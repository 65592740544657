
.prepaid{
  max-height: 48px;
}
.cod{
  flex-direction: column;
  align-items: normal;
  gap: 11px;
  
}
.cod div{
  display: flex;
  justify-content: space-between;
}
.cod span{
  background-color: #FF222A;
  border-radius: 5px;
  padding: 6px;
  color: white;
  font-weight: 400;
  font-size: 12.5px;
  display: flex
;
  
  gap: 2px;
  align-items: center;
}
.cod span img{
  object-fit: contain;
  width: 18px;
}
.prepaid-offer {
  font-size: 12px;
  font-weight: 500;
  background-color: #4FB400;
  border-radius: 60px;
  padding: 5px 8px;
  color: white;
  display: flex
;
  align-items: center;
  gap: 5px;
}
.prepaid-offer img{

  width: 23px;
}
.light-green{
  color:#4FB400 ;
}
@media(max-width:852px){
  .prepaid-offer img{

    width: 20px;
  }
  .prepaid-offer {
    font-size: 10px;

    padding: 3px 5px;

    gap: 3px;
  }
}
@media(min-width:376px){
  .prepaid-offer img{

    width: 23px;
  }
  .prepaid-offer {
    font-size: 11px;

    padding: 5px 8px;

    gap: 5px;
  }
}
@media(max-width:375px){
  .prepaid-offer img{

    width: 20px;
  }
  .prepaid-offer {
    font-size: 10px;

    padding: 3px 5px;

    gap: 3px;
  }
}