.brand__detail__Wrapper {
  padding: 20px 0;
}


.brand__detail__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  /* background-color: #fff; */           /*theme*/
  flex-direction: column;
  /* Default to column for small screens */
  text-align: center;
}

.backGround_Grey{
  background-color: #a8a3a3;
  height:320px;
  border-radius: 10px;
}

.brand__image__content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  height: 180px;
  padding: 0;
  width: 100%;
  background-color: #fff;   
}
.page-not-found-logo{
  max-width: 480px!important;
  display: flex;
  justify-content: center;
 gap: 20px;
  padding: 20px !important;
  flex-direction: column;
  align-items: center;
}
.page-not-found-logo button{
  max-width: 220px;
  
}
.brand__image__content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.brand__text__content {
  flex: 1 1 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;   
  border-radius: 10px;
}

.brand__heading h1 {
  margin: 0px;
  font-size: 1.3em;
  font-weight: 500;
}


.brand__paragraph {
  font-size: 14px;
  font-weight: 100;
  line-height: 18px;
  padding-top: 10px;
}

.brand__text__content {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brand__image__content img {
  width: 200px;
  height: 150px;
  object-fit: contain;
}


/* breadcrumn css */

/* .brand__detail__breadcrumb {
    padding: 0 20px;
} */

.brand__detail__breadcrumb .breadcrumbs {
  padding-top: 0;
}

.breadcrumbs a {
  /* color: #545454; */
  color: #fff;    /*theme*/
  text-transform: capitalize;
}


/*theme*/
.breadcrumbs {
  color: #EC4E1E;
  text-transform: capitalize;
}

.brand__details .main__image {
  border-radius: 10px;
  overflow: hidden;
}

.brand__details {

}

.brand__product-grid.list__view .product-card .card-item {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media(min-width:991px) {
  .brand__paragraph {
    font-size: 14px;
    font-weight: 100;
    line-height: 18px;
    padding-top: 10px;
  }

  .brand__detail__container {
    flex-direction: row;
    text-align: left;
    flex-wrap: nowrap;
  }

  .brand__text__content {
    flex: 1 1 90%;
    padding: 0 20px;  
  }

  .brand__image__content img {
    width: 200px;
    height: 150px;
    object-fit: contain;
  }

  .brand__image__content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 0 20px;
    height: 180px;
    width: 240px;
  }

  .brand__product-grid.list__view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }


}

@media(min-width:1200px) {
  .brand__paragraph {
    font-size: 16px;
    font-weight: 100;
    line-height: 22px;
    padding-top: 10px;
  }
}

@media(max-width:767px) {
  .brand__details .main__image {
    border-radius: 0;
    margin: 0 -15px;
  }
  .brand__detail__container{
    background-color: #fff;
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
    margin: -50px 0 0;
  }
  .brand__image__content{
    height: 80px;
    padding: 0;
    width: 100%;
    max-width: 130px;
    border: none;
    box-shadow: 0px 2px 4px -1px #00000033;
    background: var(--white);
  }
  .brand__image__content img {
    width: 100%;
    height: 100%;
  }
  .brand__text__content {
    text-align: left;
    border: none;
    padding: 0;
   } 
 }