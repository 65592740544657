.trackingWrapper {
    display: flex;
    margin: 30px 0 50px;
}

.trackingBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
}


.order-active {
    background-color: rgb(143, 238, 34);
    border-radius: 50%;
    height: 10px;
    width: 10px;
    display: block;
}

.order-inactive,
.order-active {
    background-color: #8b8b8b;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: block;
}
.order-cancel{
    background-color: #8b8b8b;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: block;
}

.order-active {
    background: #12B76A;
}

.order-active::after {
    background-color: rgb(143, 238, 34);
    height: 5px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    content: '';


}

.order-inactive::before,
.order-active::before {
    background-color: #8b8b8b;
    height: 4px;
    width: 100%;
    display: block;
    position: absolute;
    top: 36px;
    content: '';
    left: 50%;
}

.order-active:before {
    background: #12B76A;
}


.order-active:first-child::before {
    display: none;
}

.order-active:last-child::after {
    display: none;
}

.order-inactive::after {
    background-color: #8b8b8b;
    height: 5px;
    width: 50%;
    display: block;
    position: absolute;
    top: 0px;
    content: '';


}

/* .order-inactive:last-child::before{ display: none;} */
.order-inactive:last-child::after {
    display: none;
}

.order-inactive.delivered::before {
    display: none;
}

.order-active.delivered::before {
    display: none;
}

.dot {
    width: 15px;
    /* Adjust the size of the dot */
    height: 15px;
    /* Adjust the size of the dot */
    border-radius: 50%;
    background-color: #ff4500;
    /* Base color of the dot */
  
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px rgba(72, 189, 97, 0.8);
    }

    to {
        box-shadow: 0 0 20px rgb(82, 201, 45);
    }
}

@media (max-width: 767px) {
    .trackingWrapper {
        margin: 20px 0 30px;
        flex-direction: column;
        gap: 40px;
    }

    .trackingBox {
        flex-direction: row-reverse;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
    }

    .order-inactive::before,
    .order-active::before {
        background-color: #8b8b8b;
        width: 4px;
        display: block;
        position: absolute;
        top: 10px;
        content: '';
        left: 6px;
        height: 60px;
    }
}