.successfull-modalWrapper{
   
    display: flex;
    justify-content: center;
    align-items: center;
   
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
   /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

}
.successfull-modalContent {
    
    background-color: #fdfdfd;
     /* 15% from the top and centered */
   
    border: 1px solid #888;
    width: 400px; /* Could be more or less, depending on screen size */
    display: flex;
    gap:15px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    
    height: 400px;
    border-radius: 20px;
    border: 1px solid #BCBBBB;
    background-size: 'cover';
    background-position: center; /* Center the background image */
  background-repeat: no-repeat;
   
}
.successfull-content-tick{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.successfull-tick{
  
    width: 150px;
    height: 150px;
}
.thankYou-text{
    padding-top: 20px;
    color: #EC4E1E;
    font-size: xx-large;
    font-weight: bold;
}
.buttonsWrapper{
    padding: 30px;
    display: flex;
    width: 100%;
    gap: 5px;
}
.home-button{
    background-color: #EC4E1E;
    border: none;
    border-radius: 9px;
    width: 100%;
    height: 40px;
    color: #fefefe;
    cursor: pointer;
}
.back-to-cart{

    background-color: #EC4E1E;
    border: none;
    border-radius: 9px;
    width: 50%;
    height: 40px;
    color: #fefefe;
    cursor: pointer; 
    text-align: center;
}
.order-button{
    background-color: white;
    border: 1px solid #EC4E1E ;
    border-radius: 9px;
    width:100%;
    height: 40px;
    color: #EC4E1E;
    cursor: pointer;

}

.confirmCOD-modalWrapper{
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.confirmCOD-modalContent-loader .page_loading {
    background: transparent;
}

.confirmCOD-modalContent {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    display: flex;
    gap:15px;
    width: calc(100% - 30px);
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    border-radius: 20px;
    border: 1px solid #BCBBBB;
    background-size: 'cover';
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
   
}
.confirmIcon{
    height: 80px;
    width: 80px;
}
.confirmText{
    font-size: 20px;
    color: var(--black);
    font-weight: 500;
}
.OTPText{
    color: var(--orange);
    letter-spacing: 10px;
    font-size: 40px;
    font-weight: 400;
}
.otpWrapper{
    display: flex;
    gap: 20px;
}
.otpBox{
    height: 50px;
    width: 50px;
    border-width: 1.5px;
    border-style: dashed;
    text-align: center;
    border-color: #aaaaaa;
}
.buttons{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.imageUnsuccessfull{
    width: 250px;
    padding-top: 20px;
}

@media(min-width:768px){
 
    .successfull-tick{
  
        width: 200px;
        height: 200px;
    }
    
}

@media(max-width:520px){
    .confirmIcon{
        height: 50px;
        width: 50px;
    }
    .otpBox{
        height: 40px;
        width: 40px;
    }
    
}