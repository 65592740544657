.nav__menu {
    position: relative;
}

.nav_sub_menu {
    position: absolute;
    display: block;
    max-height: 80vh;
    width: auto;
    background: white;
    z-index: 9;
    box-shadow: 0px 2px 12px 0px #00000040;
    border-radius: 6px;
    overflow: auto;
}

.header__searchbar .nav_sub_menu {
    margin: 5px 20px;
}

.nav_sub_menu>ul {
    padding: 6px;

}

.nav_sub_menu>li {
    width: 100%;

}

.nav_sub_list {
    display: block;
    padding: 8px 0px;
    width: 100%;
    font: var(--sup);
    letter-spacing: 0.3px;
    color: #1C1C1C;
    position: relative;
    cursor: pointer;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

}

.nav_sub_list:hover {
    color: #EC4E1E;
}

.left__arrow__mobile__header svg {
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
}


summary.nav__menu {
    display: flex;
    max-width: 100%;
    text-align: left;
    align-content: start;
    justify-content: start;
}


@media(min-width:991px) {
    .header__searchbar .nav_sub_menu {
        margin: 5px 0;
    }
}