.focuse__items{
    display: block;
}
.header__searchbar .nav_sub_menu{
    display: none;
}
.searchList{
    display: flex;
    gap: 7px;
}
.suggestions-label,.recent-searches-label{
    padding: 5px;
    color: grey;
}
.breadcrumbs{
  font-size: 18px;
  font-weight: 400;
  line-height: 21.33px;
  text-align: left;
  color:  #5A5A5A;
}

.arrow{
    height: 14px;
    width: 14px;
}
.header__searchbar {
    position: relative;
  }
  
  .search-input {
    width: 100%;
    padding: 8px 40px 8px 12px; /* Adjust padding as needed */
    box-sizing: border-box;
  }
  
  .icon-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .searchList img{
    object-fit: contain;
}

  .nav_sub_menu {
    position: absolute;
    top: 100%; /* Positions below the input */
    left: 0;
    width: 100%;
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    z-index: 1000; /* Ensure it is on top of other elements */
    display: block; /* Hide by default */
  }
  
  .nav_sub_menu.active {
    display: block;
  }
  .main__nav-container li{
    position:relative;
}
  
  .suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .suggestions-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: #f0f0f0;
  }
  
  .searchList {
    display: flex;
    align-items: center;
  }
  
  .searchList .icon {
    margin-right: 8px;
  }
  
  .search__image{
    width:50px;
    height:50px;
  }

  .recent-searches-label, .suggestions-label {
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
@media (min-width: 991px) {
    .brand__product-list {
      
        padding: 20px 0 0px 40px;
    }
}