/* src/components/layout/header/megaMenu/MegaMenu.css */
.mega-menu {
  position: absolute;
  display: none;
  width: auto;
  background: white;
  z-index: 9;
  box-shadow: 0px 2px 12px 0px #00000040;
  border-radius: 6px;
}

a[role='button'] {
cursor: pointer;
}

.main__mega__menu-content li {
  width: 200px;
}
.mega__menu__search-bar .header__searchbar input.search-input {
  width: 100%;
}
.mega-menu-content {
display: flex;
justify-content: space-between;
}



.mega-menu-section.right {
background: rgb(236 78 30 / 10%);
margin: 0;
border-top-right-radius: 6px;
border-bottom-right-radius: 6px;
min-width: 400px;
padding: 0;
}
.mega-menu-section.right div {
padding: 20px 20px 0px;
font: var(--h5);
}
.main__mega__menu-content li svg {
width: 10px;
height: 10px;
transform: rotate(91deg);
position: absolute;
right: 5px;
padding-top:10px;
}

.main__mega__menu-content {
position: relative;
margin-right: 0;
}

.mega__menu__search-bar >div {
margin: 0;
}

.mega-menu-section ul {
  list-style: none;
  padding: 0;
  flex-direction: column;
}


.mega-menu-section{
  padding: 20px;
  width:fit-content;
}

.top__brands__item img {
  height:95%;
  object-fit: contain;
}

.navbar .mega-menu-link:hover + .mega-menu,
.mega-menu:hover {
  display: block;
}


.mega-menu-section.right .top__brands {
padding: 10px 20px;
gap: 10px;
max-height: 400px;
overflow: auto;
white-space: nowrap;
overflow-x: hidden;
flex-direction: row;
flex-wrap: wrap;
}

.mega-menu-section.left {
  width: 300px;
}

li.top__brands__item .navbar ul li a {
  display:flex;
  justify-content:center;
}

li.top__brands__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

li.top__brands__item {
  height: 60px;
}

li.top__brands__item {
  margin: 0!important;
  box-shadow: 0px 0px 7px 0px #00000040;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
  width: calc(33.3%  - 10px);
}
/* src/components/layout/header/navigation/nav.css */
.navbar {
  position: relative;
}
  .main__mega__menu-content span {
    font: var(--h6);
    margin: 0 0 5px;
  }
  .mega-menu-content .navbar ul li a {
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    display: block;
    color: #000000;
    text-decoration: none;
  }

.mega-menu-item {
  position: relative;
}

.mega-menu-item:hover .mega-menu {
  display: block;
}

.mega-menu-content ul {
  display: flex;
  list-style: none;
  padding: 0 10px 0px 0;
  margin: 0;
  flex-direction: column;
}

.mega-menu-content .navbar ul li {
  position: relative;
}

.main__mega__menu-content {
  height: 100%;
  max-height: 300px;
  overflow: auto;
  white-space: nowrap;
  margin: 20px 0 0;
}
.main__mega__menu-content span {
  font: var(--h6);
  margin: 0 0 5px;
}
.mega-menu-content .navbar ul li a {
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  display: block;
  color: #000000;
  text-decoration: none;
}

.mega-menu-section.left .main__mega__menu-content ul li a {
  font: var(--sup);
}

.mega-menu-section.left ul{
  gap:10px;
  margin:  0 0 15px;
}

/* .navbar ul li a:hover {
  background-color: #f0f0f0;
} */

.main__mega__menu-content li {
  white-space: nowrap;          
  overflow: hidden;              
  text-overflow: ellipsis;     
  width: 100%;
}


nav.main__header__wrapper.navbar {
  display: none;
}
@media(min-width:991px){
  nav.main__header__wrapper.navbar {
      display: block;
  }

  li.top__brands__item {
    height: 100px;

}
  .mega-menu-section.left {
      width: 200px;
  }
  /* .mega-menu-section {
      padding: 20px;
      width: 400px;
  } */
}

@media(min-width:1024px){
  nav.main__header__wrapper.navbar {
      display: block;
  }

  .mega-menu-section.left {
      width: 250px;
  }
  /* .mega-menu-section {
      padding: 20px;
      width: 350px;
  } */
}

@media(min-width:1200px){
  nav.main__header__wrapper.navbar {
      display: block;
  }

  .mega-menu-section.left {
      width: 300px;
  }
  /* .mega-menu-section {
      padding: 20px;
      width: fi;
  } */
}

