/* The Modal (background) */


/* Modal Content/Box */
.modal .account__form {
    display: grid;
    grid-template-columns: 1fr;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.login__container {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: calc(100% - 30px);
    /* Could be more or less, depending on screen size */
    display: flex;
    justify-content: space-between;
    align-items: normal;
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid #BCBBBB
}

.login__slider__box {
    max-width: 500px;
    width: 100%;
}

.no-focus-outline:focus {
    outline: none;
}

.no-focus-outline *:focus {
    outline: none;
}


.login__container {

    .slick-dots {
        bottom: 10px;
    }

    .slick-dots li button:before {
        font-size: 8px;
        color: #C9C9C9;
        opacity: 1;
        margin: 0;
    }

    .slick-dots li.slick-active button:before {
        color: #EC4E1E;
        width: 20px;
        height: 7px;
        background: #EC4E1E;
        border-radius: 20px;
        content: "";
    }

    .slick-dots li button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    li.slick-active {
        align-items: center;
        position: relative;
        top: 5px;
    }

    .slick-dots li {
        margin: 0;
    }
}

.loginName {
    display: inline;
}

.login__wrapper {

    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    /* Stay in place */
    z-index: 11;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */

    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.login__slider__box button.slick-arrow.slick-prev,
.login__slider__box button.slick-arrow.slick-next {
    display: none !important;
}


.login__container {
    flex-direction: column-reverse;
    gap: 20px;
}

.login__left__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
}

.login__heading {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 600;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login__sub__heading {
    font: var(--sup);
    letter-spacing: 0.5px;
    padding-bottom: 20px;
    color: #313131;
}

.login__phone__head {
    font: var(--sup);
    padding-bottom: 2px;
}

.validation-error {
    color: red;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: -15px 0 10px;
}

.login__phone__box input {
    max-width: 100%;
    margin-bottom: 15px;
    padding: 15px;
}

.login__phone__box .login-otp input {
    padding: 15px 45px;
}

.login__phone__box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 100%;
}

.login__right__content {
    max-width: 100%;
    width: 100%;
}

.login-otp {
    display: flex;
    flex-direction: column;
    position: relative;
}

.login-otp .prefix {
    position: absolute;
    right: auto;
    top: 9px;
    left: unset;
    z-index: 1;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 14px;

}

.login-otp button {
    position: absolute;
    right: 10px;
    top: 9px;
    left: unset;
    z-index: 1;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 14px;
    background: var(--orange);
    border: none;
    color: var(--white);
}

.login__submit {
    background: #EC4E1E;
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 13px;
}

.validation-error {
    color: red;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.login__wrapper input[type="text"]::placeholder {
    letter-spacing: 0.6px;
}

.login-otp .orange[disabled] {
    opacity: 0.6;
}

@media(min-width:768px) {
    .login__container {
        flex-direction: row;
        gap: 20px;
    }

    .login__left__content {
        display: flex;
        flex-direction: column;

    }

    .login__phone__box {
        display: flex;
        flex-direction: column;
        gap: 5px;

    }

    .login__heading {
        text-align: start;
    }

    .login__right__content {
        max-width: 50%;
        width: 100%;
    }

    .login__logo {
        max-width: 130px;
        width: 100%;
        margin: inherit;
    }
}

@media(max-width:768px) {
    .login-otp .orange {
        position: absolute;
        right: 10px;
        top: 9.5px;
        left: unset;
        z-index: 1;
        cursor: pointer;
        padding: 5px 5px;
        border-radius: 6px;
        font-size: 14px;
    }

}