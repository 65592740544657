.collection__wrapper {
  padding: 20px;
}

.collection__wrapper .heading__h1 {
  margin-left: 10px;
}

/* .collection__wrapper h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  } */


.product-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0px;
}

@media (max-width: 768px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width:400px) {
  .product_atc_btn .btn_text {
    font-size: 13px;
  }
}

@media(max-width:320px) {
  .product-list {
    grid-template-columns: 1fr;
  }

  .hm__product-container.sports__supplements .card-image img {
    width: 100px;
  }
}