

.home-content-container{
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 20px;

}
.rotate-180{
    transform: rotate(180deg);
}
.home-content-container h1 {
  color: #ec4e1e;

  font-size: 24px;
  font-weight: 500;
  line-height: 28.44px;
  text-align: left;
}
.home-content-container h2 {
  color: #ec4e1e;

  margin: 10px 0px;

  font-size: 24px;
  font-weight: 500;
  line-height: 28.44px;
  text-align: left;
}
.home-content-container h3 a {
  color: #ec4e1e;
}
.home-content-container h3 {
  margin: 10px 0px;

  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
.home-content-container p {
  margin: 10px 0px;
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;

}
.home-content-container .orange {
    color: #ec4e1e !important;
    font-weight: 500!important;
}
p a{
  color: black;
}
strong a{
  color: black;
}
p.view-more{
  
  background: linear-gradient(177.45deg, #5b5b5b 26.97%, #FFFFFF 96.33%);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text; /* For other browsers */
  color: transparent; /* Make the text color transparent */
}
.home-content-container ul {
  list-style-type: disc;
  padding: 0px 15px;
}
.home-content-container li {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;

  color: #5b5b5b;

}
ul li strong a {
  font-size: 16px!important;
font-weight: 600;
  line-height: 26px;
  text-align: left;

  color: #5b5b5b!important;

}

.view-more-btn {
    justify-content: center;
  border: none;
  background-color: white;
  transition: background-color 0.3s;
  color: #ec4e1e;

font-size: 17px;
font-weight: 500;
line-height: 30px;
display: flex;
align-items: center;
gap: 6px;
width: 100%;
margin-top: 10px;

}
.view-more-btn:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Slightly more opaque on hover */
}
.home-content-container a:hover{
  text-decoration: underline !important;
}
.home-content-container li a {
  margin: 10px 0px;
  color: #5b5b5b!important;
  font-size: 16px!important;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.home-content-container li strong a {
  margin: 10px 0px;
  color: #5b5b5b!important;
  font-size: 16px!important;
  font-weight: bold;
  line-height: 26px;
  text-align: left;
}
@media (max-width: 425px) {
  .home-content-container h1 {
    font-size: 20px;

    line-height: 23.44px;
  }
  .home-content-container h2 {
    font-size: 20px;

    line-height: 23.44px;
  }
  .home-content-container h3 {
    font-size: 15px;

    line-height: 25px;
  }
  .home-content-container p {
    font-size: 14px;

    line-height: 20px;
  }
  .home-content-container li {
    font-size: 14px;

    line-height: 20px;
  }
}
@media (max-width: 425px) {
    .home-content-container h1 {
      font-size: 20px;
  
      line-height: 23.44px;
    }
    .home-content-container h2 {
      font-size: 20px;
  
      line-height: 23.44px;
    }
    .home-content-container h3 {
      font-size: 15px;
  
      line-height: 25px;
    }
    .home-content-container p {
      font-size: 14px;
  
      line-height: 20px;
    }
    .home-content-container li {
      font-size: 14px;
  
      line-height: 20px;
    }
  }