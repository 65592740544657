/* --- MOBILE FILTER START HERE ---------- */
.br_filter_left_title_mob {
    padding-left: 5px;
    gap: 20px;
}

.br_filter_left_title_mob img {
    width: 20px;
    height: 20px;
    padding: 1px;
    transform: rotate(-90deg);
}

.br_filter_left_title_mob h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 18.96px;
    text-align: center;
    color: var(--black);
}
.last_Product_ElementRef{
   
    width: calc(20% - 11px);
    box-sizing: border-box;
}

.br__filter_title_mob,
.br_filter_left_title_mob,
.filter_category__name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.br__filter_title_mob {
    padding: 20px 15px;
}

.br__filter_title_mob,
.filter_category__name {
    justify-content: space-between;
}

.br_filter_menu_mob {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main_filter_mob {
    margin-top: 0;
    border-top: 1px solid #6D6D6D;
    height: 100%;
    overflow: hidden;
}

.filter_category__name {
    padding: 10px;
    font: var(--sup);
    color: var(--black);
}

.filter_category__name:hover,
.active_filter_mobile .filter_category__name {
    color: var(--black);
    background: var(--white);
}

.filter_category__name:hover img,
.active_filter_mobile .filter_category__name img {
    filter: invert(1);
    -webkit-filter: invert(1);
}

.main_filter_mob_wrap {
    position: relative;
}

.top_category_back_name {
    display: none;
}

.main_filter_mob_wrap,
ul.main_filter_sub_menu {
    height: 100%;
}

.main_filter_sub_menu {
    overflow: auto;
    max-width: 160px;
    width: 100%;
    background: rgb(142 142 142 / 10%);
}

.brand_filteritem.see_all {
    font-size: 14px;
    padding: 10px 0;
}

.filter_category_sub_menu {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    padding: 15px;
    background: #fff;
    z-index: 2;
    width: calc(100% - 160px);
    overflow: auto;
    transition: 0.3s all ease-in-out;
    display: none;
}

li.cfsm_row_item {
    padding: 8px 0px;
    width: 100%;
    font: var(--sup);
    letter-spacing: 0.3px;
    color: #1C1C1C;
    text-align: left;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.cfsm_row_item input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #BDBDBD;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    padding: 0;
    background: #fff;
    margin-right: 5px;
    flex-shrink: 0;
    position: relative;
}

.cfsm_row_item input[type="checkbox"]:checked,
.cfsm_row_item input[type="checkbox"]:hover {
    border-color: #EC4E1E;
    background: #EC4E1E;
}

.cfsm_row_item input[type="checkbox"]:checked::before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.br_filter_right_close {
    cursor: pointer;
}

.br_filter_right_close img.close_filter {
    width: 20px;
    height: 20px;
}

.brand__filters_mob {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}

.brand__filters_mob.active {
    opacity: 1;
    visibility: visible;
}

.filter_overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    inset: 0;
}

.brand__filters_mob .br_filter_menu_mob {
    background-color: var(--white);
    position: relative;
    max-width: 450px;
    width: 100%;
    padding: 0;
    transform: translate(-100%, 0px);
    transition: all 0.3s ease-in-out;
}

.brand__filters_mob.active .br_filter_menu_mob {
    transform: translate(0px);
}

.active_filter_mobile .filter_category_sub_menu {
    display: block;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

li.cfsm_row_item.see__all_mobile {
    color: var(--orange);
}

.apply_filter_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #c2c2c2;
}

.apply_filter_btns .filter_action_mob {
    width: 50%;
    padding: 15px 15px;
    border: none;
    background: transparent;
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--primary-font);
}

.apply_filter_btns .cancel_fl {
    border-right: 1px solid #c2c2c2;
}

.apply_filter_btns .filter_action_mob.apply_fl {
    color: var(--orange);
}

@media screen and (max-width: 767px) {
    .brand__filters {
        display: none;
    }

    .brand__filter__item {
        gap: 6px;
        align-items: flex-start;
    }

    .brand__filter__item input {
        margin: 2px 0 0;
        appearance: none;
        width: 14px;
        height: 14px;
        padding: 0;
        border-radius: 4px;
        flex-shrink: 0;
        position: relative;
    }
    .brand__filter__item input:checked {
        background: var(--orange);
        border-color: var(--orange);
    }
    
    .brand__filter__item input:checked:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 7px;
        inset: 0;
        margin: auto;
        border-right: 2px solid var(--white);
        border-bottom: 2px solid var(--white);
        transform: rotate(35deg);
        top: -2px;
    }
    .main_filter_mob {
        display: flex;
        flex-direction: column;
    }
}

/* --- MOBILE FILTER END HERE ---------- */


