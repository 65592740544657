.account__menu {
    display: none;
}

.account__menu.active {
    display: block;
}

.header__account.active {
    position: relative;
}

.account__menu.active {
    position: absolute;
    /* border: 0.6px solid #F5F5F5; */
    height: auto;
    width: auto;
    left: auto;
    right: 50%;
    background: #fff;
    border-radius: 6px;
    padding: 0;
    color: #000;
    box-shadow: 0px 0px 4px 0px #00000040;
    top: 30px;
}

.account__submenu {
    width: 170px;
    max-width: 200px;
    color: #000;

}

.active.main__account__menu__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.main__account__menu__item .account__submenu:hover {
    background-color: #EC4E1E;
    color: #fff;
    border-radius: 4px;
}

.account__submenu.account-info svg {
    height: 22px;
    width: 20px;
}

.account__submenu.account-info:hover svg path {
    fill: #fff !important;
}

.account__submenu {
    padding: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 6px;
}

.account__submenu svg path:first-child {
    fill: #EC4E1E !important;
}

.header__account.main__account__menu__item,
.header__icon-link {
    min-width: 26px;
    position: relative;
    cursor: pointer;
    display: flex;
}



.account__submenu svg path {
    fill: #EC4E1E !important;
    stroke: none !important;
}

.main__account__menu__item.active .account__menu {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: 0.5px;
    z-index: 1;
}

.header__bag a {
    display: block;
    line-height: 0;
}