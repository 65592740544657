.nav__menu {
  font: var(--sup);
  color: #6D6D6D;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  /* font-weight: 400;     */
  /*theme*/
}

.nav__menu:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--orange);
  left: 0;
  bottom: -8px;
  opacity: 0;
}

.main__nav-container>li:hover .nav__menu:before {
  opacity: 1;
}

nav.main-nav {
  display: none;
  border-top: 1px solid #6D6D6D;
  border-bottom: 1px solid #6D6D6D;
  background-color: #ffffff;     /*theme*/
  border-radius: 10px;                /*theme*/
  width: 85% ; /*theme*/
  margin: auto;
  margin-top: -20px;   /*theme*/

}

.main__nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  width: 100%;

}

/*theme*/
/* .main-nav {
  position: relative;                
} */

.main__nav-container>li {
  padding: 10px 10px;
}

.search-input {
  transition: border-color 0.3s ease-in-out;
}

.search-input:focus {
  outline: none;
  border-color: #EC4E1E;
}

.nav_menu_main .nav_sub_menu {
  padding: 0;
  border: none;
  min-width: 220px;
  left: 18px;
  top: calc(100% - 10px);
  border-radius: 0px 0px 6px 6px;
  max-height: 60vh;
}

nav_menu_main ul {
  padding: 0;
}

.nav_menu_main .nav_sub_menu ul {
  padding: 10px 0;
}

.nav_menu_main .nav_sub_menu ul li {
  padding: 8px 0;
}

.nav_menu_main .nav_sub_menu ul li .nav_sub_list {
  font: var(--sup);
  padding: 0 16px;
}

@media screen and (min-width:991px) {
  nav.main-nav {
    display: block;
  }
}

@media screen and (max-width:991px) {

  .menuDrawer .main-nav {
    display: block;
    height: 100%;
    overflow: auto;
    border-bottom: 0;
    margin: 0;
  }

  .menuDrawer .main-nav .page-width {
    padding: 0;
    height: 100%;
    overflow: auto;
  }

  .main__nav-container li {
    width: 100%;
  }

  .main__nav-container li {
    position: unset;
  }

  div#menuDrawer .main__nav-container li.active {
    background: #fff;
  }

  /* div#menuDrawer .main__nav-container li.active .nav__menu {
    font:normal normal 500 16px/18.96px "Rubik";
  } */
  div#menuDrawer .main__nav-container li.active .nav__menu:before {
    display: none;
  }

  .main__nav-container>li {
    padding: 12px 15px;
  }

  .menuDrawer .main__nav-container.selected_subnav {
    max-width: 160px;
    width: 100%;
    background: rgb(142 142 142 / 10%);
  }

  ul.subCategoryMenu {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    padding: 15px;
    background: #fff;
    z-index: 2;
    width: calc(100% - 160px);
    overflow: auto;
  }

  .menuDrawer .main__nav-container {
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
}