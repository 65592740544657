.cartEmpty {
    padding: 10px;
    font-weight: 600;

}

.emptyCartImage {
    width: 50vh;
}
.light-green{
    color:#4FB400 ;
  }

.startShopping_contanier {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart__heading {
    margin: 0 0 30px;
}
  
.modal {
    display: flex;
    justify-content: center;
    align-items: center;

   
    z-index: 1;
    left: 0;
    top: 0;
 
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modalWrapper {
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    /* Stay in place */
    z-index: 11;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
 
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */

}

.modalContent {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px 15px;
    border: 1px solid #888;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: normal;
    max-width: 400px;
    height: 100%;
    max-height:340px;
    min-height:340px;
    max-width: 400px;
    border-radius: 20px;
    border: 1px solid #BCBBBB;
    overflow-x: auto;
}

.addressDisplay {
    margin: 20px 0 10px;
    box-shadow: 0px 0px 15px 0px #0000000F;
    border: 1px solid #BCBBBB;
    border-radius: 8px;
    padding: 15px 20px;
    cursor: pointer;
}

.account_card_right {
    padding: 0px 0 20px 0;
    background-color: #fff;   /*theme*/
}

.address_heading {
    display: flex;
    justify-content: space-between;
}

.address_display {
    text-align: start;
    padding: 10px 0 0;
    display: flex;
    gap: 10px;
    font-size: 14px;
    font-weight: 300;
}

.addressDisplayButton {
    background-color: white;
    color: #EC4E1E;
    display: flex;
    align-items: center;
    gap: 7px;
    border: none;
    font: var(--sup);
}

.orderType {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cart__save__amount {
    font: var(--h6);
    font-weight: 400;
}

.save_img img {
    width: 25px;
}

.orderTypeDiv {
    padding: 11px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px #00000026;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.addressChangeButton {
    color: var(--orange);
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.addIcon {
    height: 14px;
    width: 14px;
}

.location_icon {
    height: 22px;
    width: 22px;
    color: #EC4E1E;
}

.StartShopping__btn {
    border: none;
    border-radius: 5px;
    padding: 8px;
    width: max-content;
    cursor: pointer;
}


.payment_type input {
    appearance: none;
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
}

.payment_type {
    display: flex;
    align-items: center;
    gap: 7px;
}

.payment_type label {
    font: var(--h6);
    position: relative;
}

.payment_type input:checked {
    border-color: var(--orange);
}

.payment_type input:checked+label:before {
    content: "";
    position: absolute;
    left: -18px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--orange);
    top: 7px;
}

.cart__item__containers {
    padding: 5px 20px;
    background-color: #fff;   /*theme*/
}

.cart__item__buttons .quantity__selector .input__value {
    height: auto;
    width: 30px;
    flex-shrink: 0;
}

.cart__item__content {
    border-bottom: 1px dashed #ADADAD;
    padding: 10px 0;
}

.cart__item__container {
    gap: 20px;
}

.cart__item__image {
    width: 100%;
    max-width: 115px;
    position: relative;
    padding: 0 0 19%;
    overflow: hidden;
}

.cart__item__image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
}

.cart__item__title {
    font: var(--h6);
    font-weight: 400;
    margin: 0 0 10px;
    cursor: pointer;
}
.cart__item__title:hover {
    color: var(--orange);
}

.cart__item__container .cart__item__discounts {
    margin-left: 5px;
    font: var(--sup);
}

.cart__item__contents .cart__item__price {
    margin: 0 0 5px;
}

.cart__item__variant {
    font: var(--sup);
    margin: 0 0 15px;
}

.cart__item__buttons .quantity__selector__container .heading {
    display: none;
}

.cart__quantity .minus__btn,
.cart__quantity .plus__btn,
.cart__quantity .input__value {
    padding: 10px;
    font-size: 12px;
    width: 30px;
}

.cart__quantity .minus__btn img,
.cart__quantity .plus__btn img,
.cart__quantity .input__value {
    height: 10px;
    width: 10px;
}

.cart__quantity .minus__btn,
.cart__quantity .plus__btn,
.cart__quantity .input__value {
    padding: 10px;
    font-size: 12px
}

.cart__quantity .minus__btn img,
.cart__quantity .plus__btn img,
.cart__quantity .input__value {
    height: 10px;
    width: 10px;
}

.cart__remove {
    padding: 8px;
    border: 1px solid #EC4E1E;
    border-radius: 6px;
    cursor: pointer;
    max-width: 120px;
    width: 100%;
}

.remove__btn__icon img {
    height: 15px;
    width: 15px;
}

.remove__btn__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #EC4E1E;
}

.dot__line.pd-lr {
    margin: 0 20px;
    padding-bottom: 20px;
}

.dot__line.pd-lr {
    margin: 0 20px;
}

.dot__line.pd-lr:last-child {
    margin: 0px 20px 15px 20px;
}

.cart__container {
    border: 1px solid #DEE2E7;
    border-radius: 10px;
}

.cart_left_sec {
    width: 65%;
}

.cart_right_sec {
    width: 35%;
    padding: 20px 15px;
    background-color: #fff;  /*theme*/
}

.cart__wrapper {
    margin: 50px 0;
}

.cart-item .submit__buttons.flex {
    display: flex;
    justify-content: center;
}

.cart__wrapper__buttons>div {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.main__cart__container {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.cart__discount__item input[type="text"] {
    background-repeat: no-repeat;
    align-content: center;
    background-size: 20px;
    background-position: 10px center;
    padding: 15px 45px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALeSURBVHgBjVRdTttAEJ7ZOHlKUqMCLWoTmRPgnqDpW1WR1JwgcALgBA0nIDkB6QkIlIe+AScgnCARINFCUEz7hsluv3GzKHWN5JVsyzvfzs73zQ9RhjVqVJt3jer4rlHZpYyL0zbhZNMYExjiA8X0whC18Owooib2+4AckOL3pI0XRdHG0vcfw6QPldy4+fTGh5O2OGXFnw2zP9FmbeHwovWg1AfNfE9KNbWhe2Z2846zRVkWov2CZ5AFe9uotoA9G9dcN2lz5DWAoVwuC/0afv1HbTbsfrFY9BeOrk7SHDtKtbXWTV0un43qpT5k2bayxFKUiqUa6G8JfdB99+rosncTeD4uGyil9kf1ynGS1W29ujXXG4YvDy+WJ8bsYNt1CoW2xcSODZkwNvz+1V3qDeMbWetAEoWI1oi5ZunGiUUyH3OqZ50sfrvsQnfAaPyPY0v1EbStwSgcROJYqWPSujN3EobXH197JBViaNsGYBcb42ltTilJLS1h14HniSRWb8GMVt+2KWVNEzmYXk6OaIXv+kSptSR4NqpSqRQ3x/zRVWp5SSKh9UqhUJB8LEMY+lsqDxE9t4QRarYmdfwcJgwlTfrpX0nh4/ercnLHafU4bZgWSjDWVSSRvSROGLFh316uLA183Mh1vVmw6JVzcvvSzlKCP1crQalcHiOIM2ExiwUjH93YsfLFjqOHKHY4AaNZMPTaR0v3hZX855iakK6LGSH5WJ/FAhey0d6TFPErp4Ra6Ex0YLMqX9RxGDFvW7CW4WNoHTNkz2h9IHsiDRpILgkR9soTA3mNA8+NtG4pMUhbI6J5UKeUJSWYg2yLvWFfLp9WgWuYeppVR/bTzsnsbWcdQjeIVLADBJa0cVpEea0HHHeYEYlcqRppW4nQyec3YfMh4hA2jybmPK22/5vH06x2ZA5jKJ1Ct3NItHdXr+wK7dgpLtIA4HgYOU5qJ2ZaQhkSmdFqpZv1zB8GSGNOhotjxQAAAABJRU5ErkJggg==);
    width: 100%;
    box-shadow: 0px 0px 15px 0px #0000000F;
}

.cart__discount__item input[type="text"]::placeholder {
    color: #000;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 0 5px
}

.coupon_sidebar {
    position: fixed;
    inset: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
    z-index: 15;
}

.coupon_overlay {
    position: absolute;
    inset: 0;
    background: rgb(0 0 0 / 30%);
}

.coupon_side_wrap {
    position: relative;
    z-index: 1;
    max-width: 400px;
    width: 100%;
    margin: 0 0 0 auto;
    height: 100%;
    background: var(--white);
    transition: all 0.4s ease-in-out;
    transform: translate(100%, 0px);
    display: flex;
    flex-direction: column;
}

.coupon_items {
    padding: 20px 20px;
}

.add_cop_name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px;
}

.close_cp_bar {
    padding: 10px;
}

.close_cp_bar img {
    width: 15px;
}

.cart__discount__input {
    position: relative;
}

.cart__discount__input input {
    width: 100%;
    font: var(--sup);
    font-weight: 400;
    padding: 12px 20px;
}

.cart__discount__input input::-webkit-input-placeholder {
    font: var(--sup);
    font-weight: 400;
}

.cart__discount__input input::-moz-placeholder {
    font: var(--sup);
    font-weight: 400;
}

.cart__discount__input input:-ms-input-placeholder {
    font: var(--sup);
    font-weight: 400;
}

.cart__discount__input input:-moz-placeholder {
    font: var(--sup);
    font-weight: 400;
}

.cart__discount__item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cart__discount__apply__btn span {
    color: var(--orange);
    font: var(--sup);
    letter-spacing: 0.5px;
    font-weight: 500;
}

.cart__discount__apply__btn {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 11px 20px;
    cursor:pointer;
}

.cart__discount__item input[type="text"]:focus-visible {
    border: 1.5px solid #EC4E1E;
    outline: none;
}

.cart__final__conatainer {
    display: flex;
    flex-direction: column;
}

.cart__final__conatainer {
    box-shadow: 0px 0px 15px 0px #0000000F;
    border: 0.6px solid #8D8D8D;
    max-width: 90%;
    width: 100%;
    margin: 20px auto 0;
    border-radius: 6px;
}

/*  */
.cart__final__total {
    box-shadow: 0px 0px 15px 0px #00000014;
    border: 1px solid #4A4A4A;
    border-radius: 6px;
    margin: 15px 0;
}

.cart_top_item {
    padding: 15px 20px 10px;
}

.cart__amount__pay .cart__final__price__heading {
    color: #8C8C8C;
}

.cart__amount__pay .cart__final__price__heading,
.cart__amount__pay .cart__final__price__amount {
    font: var(--sup);
    font-weight: 400;
}

.cart__amount__pay.total {
    padding: 10px 0 0;
    border-top: 1px dashed #3D3D3D;
}

.cart__amount__pay.total .cart__final__price__heading {
    color: var(--black);
    font-weight: 500;
}

.cart__amount__pay .cart__final__price__amount {
    font-weight: 500;
}

.cart__amount__pay {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px;
}

.cart__amount__pay.saved-amount {
    background: #EC4E1E26;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
    justify-content: center;
    gap: 5px;
    color: #000;
}

.submit__buttons__container.checkout-btn {
    margin: 0;
    padding: 0;

    div {
        background: #fff;
        color: #000;
        border-radius: 6px;
        gap: 0;
        padding: 0 10px
    }
}

.cart__amount__pay.address {
    padding-bottom: 10px;
}

.cart__amount__pay.grand-total {
    margin-top: 5px;
    background: #EC4E1E;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.successfull_modall {
    
    justify-content: center;
    align-items: center;
    /* margin: 0 auto; */
   
    display: block;
    /* Hidden by default */
    /* position: fixed; Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
  
    /* Full width */
   
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.cart_bottom_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 20px 0;
    background-color: #fff;
}

.cart_bottom_btns .buy__now__btn,
.cart_bottom_btns .add__to_cart__btn {
    max-width: 190px;
    width: 100%;
}

.cart_total_main {
    background: var(--orange);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.g_total_title {
    display: flex;
    flex-direction: column;
    color: var(--white);
    font: var(--sup);
}

.g_total_title span {
    font: var(--h6);
}

.submit__buttons.btn_primary {
    background: var(--white);
    color: var(--black);
    font: var(--h6);
    padding: 10px 20px;
    font-weight: 400;
}

.add_coupon_code {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px #00000026;
    cursor: pointer;
}

.apply_coupon_code {
    display: flex;
    align-items: center;
    gap: 15px;
}

.apply_coupon_code img {
    width: 20px;
}

.apply_coupon_code h6 {
    font-weight: 400;
}

.apply_coupon_code .cp_img_arrow {
    height: 12px;
    margin: 0 0 0 auto;
}

.add_coupon_code h6 {
    text-transform: uppercase;
    font-weight: 500;
}
p.promocode__input__error {
    margin-top: 5px;
}

.add_coupon_code .sup {
    font: var(--sup);
    text-transform: uppercase;
    margin: 0 0 0 auto;
    color: var(--orange);
}

.coupon_code_inner {
    padding: 20px 20px;
    border-top: 1px dashed var(--black);
    flex: 1;
    overflow: auto;
}

.coupon_code_inner .coupon_main_card:not(:last-child) {
    margin: 0 0 15px;
}

.coupon_sidebar.active {
    opacity: 1;
    visibility: visible;
}

.coupon_sidebar.active .coupon_side_wrap {
    transform: translate(0px, 0px);
}

.emptyCart_container {
    max-width: 1440px;
    width: 100%;
    padding: 40px 0;
    border: 1px solid #DEE2E7;
    border-radius: 15px;
}

.emptyCart_container img {
    max-width: 340px;
    width: 100%;
    margin: 0 auto 30px;
}

.emptyCart_container .startShopping_contanier {
    max-width: fit-content;
    margin: 0 auto;
}

.emptyCartDisplay {
    padding: 50px 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.My_cart_empty {
    text-align: left;
    display: block;
    max-width: 1616px;
    width: 100%;
    padding-bottom: 16px;
}

.My_cart_empty h4 {
    font: normal normal 500 24px/32px 'Rubik';
    letter-spacing: -0.2px;
    color: #1C1C1C;
}

.cart_empty_cart_wrap {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 0 20px;
}

.cart_empty_cart_wrap h5 {
    font: normal normal 700 30px/1.2 var(--primary-font);
    padding-bottom: 20px;
}

.cart_empty_cart_wrap p {
    font: var(--h6);
    font-weight: 400;
}

.img_found_wrap {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.not_found_text h5 {
    text-align: center;
    font: normal normal 700 36px/42.66px "Rubik";
    color: #5A5A5A;
}

.not_found_text {
    margin-top: 40px;
}

.not_data_found_wrap {
    padding: 40px 0;
}
.offer{
    padding: 10px;
}
.offer__item__image {
    width: 100%;
    max-width: 60px;
    position: relative;
   
    overflow: hidden;
}
.offerBox{
    width: 100%;
    background-color: #4FB400;
    /* background-color: #D5272E; */

    ;
    padding: 10px;
    color: white;
    border-radius: 15px;
    margin:0 0 15px 0 ;
    display: flex;
    gap: 10px;


}

.offerBox .flex{
    gap:4px
}
.offerText{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}
.text1{
   
font-size: 12px;
font-weight: 600;
line-height: 18px;
text-align: left;

}
.text2{
   
  
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    
    
    }

    .small__icon{
        height: 8px;
        width: 8px;
    }
@media(min-width:768px) {
    .successfull-tick {
        width: 200px;
        height: 200px;
    }
   
}

@media(min-width:991px){
    .modalContent {
        background-color: #fefefe;
        margin: 15% auto;
        padding: 20px 15px;
        border: 1px solid #888;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: normal;
        max-width: 400px;
        height: 100%;
        max-height:480px;
        min-height:480px;
        max-width: 400px;
        border-radius: 20px;
        border: 1px solid #BCBBBB;
    }

}
@media(max-width:990px) {
    .cart__wrapper {
        margin: 30px 0;
    }

    .cart_left_sec {
        width: 56%;
    }

    .cart_right_sec {
        width: 44%;
    }

    .cart__heading {
        margin: 0 0 20px;
    }

    .emptyCart_container {
        padding: 30px 0;
    }

    .emptyCartDisplay {
        padding: 40px 20px 60px;
    }

    .cart_empty_cart_wrap h5 {
        padding-bottom: 15px;
        font: normal normal 700 28px/1.2 'Rubik';
    }

    .cart_empty_cart_wrap {
        padding: 0 0 15px 0;
    }

    .emptyCart_container img {
        margin: 0 auto 20px;
    }

    .emptyCart_container img {
        max-width: 375px;
    }

    .not_data_found_wrap {
        padding: 30px 0;
    }

    .img_found_wrap {
        max-width: 410px;
    }

    .not_found_text {
        font: normal normal 700 28px/36.66px "Rubik";
    }

    .not_found_text {
        margin-top: 30px;
    }
}

@media(max-width:767px) {

    .successfull-modalContent {
        min-width: 200px;
        max-height: 400px;
    }

    .cart__item__containers {
        padding: 0px 10px;
        background-color: #fff;   /*theme*/
    }

    .cart_bottom_btns {
        gap: 10px;
        padding: 10px;
    }

    .cart_bottom_btns .buy__now__btn .buy__now__btn,
    .cart_bottom_btns .add__to_cart__btn {
        max-width: 100%;
        width: 100%;
        min-width: unset;
        padding-inline: 10px;
    }

    .buy__now__btn .btn_primary img {
        width: 18px;
    }

    .cart__item__container {
        gap: 10px;
        align-items: flex-start !important;
    }

    .cart__item__image {
        max-width: 70px;
    }

    .main__cart__container {
        flex-wrap: wrap;
    }

    .cart_left_sec,
    .cart_right_sec {
        width: 100%;
    }

    .cart_total_main {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px -5px 10px 0px #00000040;
    }

    .cart__item__title {
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .emptyCart_container {
        padding: 30px 20px;
    }

    .cart_empty_cart_wrap h5 {
        padding-bottom: 10px;
        font: normal normal 700 22px / 1.2 'Rubik';
    }

    .cart_empty_cart_wrap p {
        font: var(--sup);
    }

    .startShopping_contanier {
        flex-direction: column;
    }

    .cartEmpty.page-width.submit__buttons {
        margin-bottom: 10px;
    }

    .My_cart_empty h4 {
        font: normal normal 500 20px/24px 'Rubik';
    }

    .My_cart_empty {
        padding: 0 0 10px 0;
    }

    .emptyCartDisplay {
        padding: 40px 20px;
    }
}

@media(max-width:640px) {
    .img_found_wrap {
        max-width: 410px;
    }

    .not_found_text {
        margin-top: 20px;
    }

    .not_found_text h5 {
        font: normal normal 700 20px/26px "Rubik";
    }

    .successfull-modalContent {
        max-width: 80%;
        max-height: 350px;
    }
}


@media(max-width:320px) {

    .cart__discount__item input[type="text"]::placeholder {
        padding: 0;
    }
   

    .cart__discount__item input[type="text"] {
        padding: 15px 40px;
    }
    .offer__item__image{
        max-width: 50px;
    }
    .text1{
        font-size: 12px;
        font-weight: 500;     
        line-height: 13px;  
        }
        .text2{
            font-size: 11px;
            font-weight: 400;       
            }
            .small__icon {
                height: 6px;
                width: 6px;
            }
}