.brand__grid_heading {
  text-align: start;
  margin-bottom: 5px;
  padding: 0;
}

.loadmore__btn.brand__page {
  padding: 10px 25px;
  background: #EC4E1E;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  color: #fff;
  max-width: 100px;
  margin: 0 auto;
  cursor: pointer;
}


.loadmore__btn.brand__page:hover {
  background: #ffffff;
  border: 1px solid #EC4E1E;
  border-radius: 6px;
  color: #EC4E1E;
  max-width: 100px;
  margin: 0 auto;
  cursor: pointer;
}

.brand__grid_heading.ban__banner-heading {
  display: flex;
  justify-content: space-between;
}

.brand__grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  row-gap: 30px;
}

.brand__grid__scroll {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-x: auto;
}

.brand__content {
  width: 25%;
  padding: 0 10px;
}

.brand__content .brand__img {
  position: relative;
  padding: 0 0 61.6%;
  border-radius: 15px;
  overflow: hidden;
}

.brand__content .brand__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  position: absolute;
}

.brand__slider-container .slick-slide {
  margin: 0 5px;
}


.brand__bottom {
  background: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 180px;
  width: 100%;
  margin: -40px auto 0;
  padding: 0 0 71px;
  box-shadow: 0px 4px 10px 0px #1B1A2740;
}

.brand__bottom img {
  position: absolute;
  object-fit: contain;
  width: 80%;
  height: 80%;
  inset: 0;
  margin: auto;
  border-radius: 0px;
}

.brand__container-item {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
}


@media(min-width:600px) {
  .brand__container-item {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 20px;
  }
}

@media(min-width:768px) {
  .brand__container-item {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 20px;
  }
}

@media(min-width:800px) {
  .brand__container-item {
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 20px;
  }
}

@media(min-width:991px) {
  .brand__container-item {
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    gap: 20px;
  }
  /* .brand__grid__component .brand__grid {
    gap: 0;
  } */
}

@media(max-width:991px) {
  .brand__grid__component .brand__content {
    width: 33.33%;
  }

  .brand__grid__component .brand__grid {
    gap: 1;
  }
}
@media(max-width:600px){
  .brand__grid__component .brand__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    gap:0;
}
.brand__grid__component .brand__content .brand__img {
  padding: 0px 0 60%;
  border-radius: 10px;
}
.brand__grid__component .brand__bottom{
  max-width:180px;
  margin: -55px auto 0;
}

  .brand__grid__component .brand__content {
      width: 100%;
  }
}

@media(max-width:640px) {
  .brand__slider-container .slick-slide {
    margin: 0;
  }

  .brand__content {
    padding: 0px 5px 20px;
  }

  .brand__bottom img {
    margin: auto;
    /* border-radius: 20px; */
  }
  
  .brand__content .brand__img {
    padding: 0px 0 80%;
    border-radius: 10px;
  }

  .brand__bottom {
    max-width: 110px;
    margin: -40px auto 0;
    padding: 0px 0 71px;
    border-radius: 4px;
  }

  .brand__slider-container {
    margin: 0 -15px 0 -5px;
  }

  /* .brand__grid__component .brand__content {
    width: 50%;
  } */
}

@media(max-width:500px) {
  .cart__container.right {
    width: 100%;
  }

  .brand__product-grid.grid__view {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 5px;
  }
  .brand__grid__component .brand__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    gap:0;
}
.brand__grid__component .brand__content .brand__img {
  padding: 0px 0 60%;
  border-radius: 10px;
}
.brand__grid__component .brand__bottom{
  max-width:120px;
  margin: -20px auto 0;
  padding: 0px 0 50px;
}


  .product_atc_btn .btn_text {
    font-size: 12px !important;
    font-weight: 400;
  }
}

@media(max-width:320px) {
  .brand__container-item {
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    gap: 20px;
  }

  .brand__grid__component .brand__bottom {
    max-width: 100px !important;
    margin: -20px auto 0;
    padding: 0px 0 50px;
    border-radius: 4px;
  }
  /* .brand__bottom {
    padding: 5px 20px;
  } */

  .brand__product-grid.grid__view {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 5px;
  }

  .product_atc_btn img {
    height: 18px;
    width: 15px;
  }

}


/* brandGrid.css */

.brand__grid__mobile {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-gap: 16px;
}

.brand__scrollable {
  display: flex;
  overflow-x: auto;
  margin-top: 16px;
  padding-bottom: 16px;
}

.brand__scrollable .brand__content {
  flex: 0 0 auto;
  margin-right: 16px;
}

.hm__product-container.sports__supplements .card-image img {
  width: 200px;
}

@media(max-width:767px) {
  .product__color__item .product-card .card-item {
    margin-bottom: 20px;
  }
}

@media(max-width:500px) {
  .product-list {
    grid-template-columns: 1fr 1fr;
  }

  .hm__product-container.sports__supplements .card-image img {
    width: 120px;
  }
}

@media(max-width:400px) {
  .product-list {
    grid-template-columns: 1fr 1fr;
  }

  .hm__product-container.sports__supplements .card-image img {
    width: 100px;
  }
}