.page-wrapper{
    padding:  20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.thankYouIcon{
    width: 100vh;
    height: auto;
    margin: 30px 0px;
    border-radius: 15px;
}
.summaryWrapper{
    padding: 20px;
    margin: 30px 0px;
    width: 60%;
    height: auto;
    border-radius: 15px;
    border: 1px solid #DEE2E7;
}
.summaryBox{
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-image: url('./../../../assests/icons/greyRectangle.svg'), url('./../../../assests/icons/Summary.svg');
    background-size: contain,contain; /* Adjust each image's size */
    background-position: top, center; /* Position images */
    background-repeat: no-repeat, no-repeat; /* Prevent repetition */
}


@media(min-width:1024px){
    .thankYouIcon{
        width: 40%;
        height: auto;
        margin: 30px 0px;
        border-radius: 15px;
    }
}