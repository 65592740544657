.offer-page .product-card {
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  position: relative;
  margin-bottom: 4%;
}

.offer-page .product-card::after {
  content: '';
  position: absolute;
  bottom: -10px;  
  left: 14%;
  width: 70%;
  height: 10px;  /* Adjust height of the gradient */
  background: linear-gradient(to top, #F48C03, #F8BA68);  /* Gradient from bottom to top */
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.offer-page .collection__wrapper .product-card {
  padding: 25px 0 0;
}

.offer-page .card-wrapper {
  height: 100%;
  border-radius: 10px;  /* Retain general border radius for other corners */
  overflow: hidden;     /* Prevents content overflow */
  border-top-right-radius: 150px;
  border-top-left-radius: 150px;
  border-top: 5px solid #F48C03;
}

.offer-page .card-item {
  display: flex;
  margin-bottom: rem;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 4px 1px #00000033;
  height: 100%;
  background: var(--white);
  border: 3px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.offer-page .card-item a {
  display: block;
  width: 100%;
}

.offer-page .card-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.offer-page .card-image {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0 0 95%;
}

.offer-page .card-image img {
  width: 80%;
  height: 80%;
  position: absolute;
  object-fit: contain;
  margin: auto;
  inset: 0;
}

.brand__count__name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px; /* Adjust the size as per your design */
  font-weight: bold;
}

.line {
  display: inline-block;
  height: 1px;
  width: 29rem;
  background-color: #EC4E1E; /* Line color */
  margin: 0 10px; /* Space between the text and the lines */
  color: #EC4E1E;
  font-size: 24px;
  line-height: normal;
}

.line-text {
  color: #EC4E1E;
  font-family: Josefin Sans;
font-size: 26px;
font-weight: 800;
text-align: center;

}

.product__discount {
 
}

.brand__product-list-header {
  border: none;
}


.offer-page .card_var_title {
  display: none;
}

.offer-page .card_var_body {
  margin-top: 20%;
}

@media (max-width: 768px) {
  .line {
    width: 97px;

  }
  .line-text {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .line {
    width: 220px;
  }
  .line-text {
    font-size: 27px;
  }
}
