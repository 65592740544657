.page-2-wrapper{
	color:  #2C3742;
	;
}
.page-2-container.page-width{
	background-color: #F2F2F2;
}

.page-2-container{

	background-color: #F2F2F2;
}
.page-2-wrapper {
	display: flex;
	gap: 30px;
	padding-bottom: 30px;
}

.store-timing-container{
width: 40%;

background-color: white;
border-radius: 20px;
height: max-content;

}
.detail-contanier{
	width: 60% !important;
	background-color: white;
	border-radius: 20px;
}
.store {
	width: 100%;
	
padding: 19px;
display: flex;
gap: 20px;
flex-direction: column;
}

.store-info {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 15px;
	box-shadow: 0px 0px 4px 0px #00000029;
	border-radius: 10px;
	gap:10px;

}
.store-info p {

font-size: 14px;
font-weight: 500;
line-height: 15.48px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #474747;

}

.store-info-details{
	display: flex;
	align-items: center;
	
	gap: 7px;
}
.store-info img {
	
	height: 20px; 
	width: 20px;
}
.store-timing {
	background-color: #fff;
	padding: 15px;
	border-radius: 10px;
	box-shadow: 0px 0px 4px 0px #00000040;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 7px;
	position: relative;
}
.store-open {
    position: absolute;
    background: #EC4E1E;
    padding: 5px;
    width: 100px;
    border-bottom-left-radius: 15px;
    right: -1px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 15px;
    top: -1px;
    text-align: center;
	color: white;
}
.store-timing h3{
	font-family: Rubik;
font-size: 20px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.store-timing p{
	
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #454545;
;
}
.store-timing form {
	display: flex;
	
	gap: 1.5%;

}
.store-timing label {
	
	font-size: 11px;
	display: flex;
	flex-direction: column-reverse;
	gap:5px;

	font-weight: 400;
	line-height: 20px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #454545;
	;


}
.store-timing input
 {
	border: 0.6px #4CAF50 !important;
}
.logos {
	background-color: #fff;
	
	border-radius: 5px;
	
	display: flex;
	justify-content: space-evenly;
	/* gap: 20px;
    height: 126px; */
}
.logos img {
	object-fit: contain;

    width: 100%;
	
}
.store-timing h1{
	margin: 0px !important;
}
.card-box-wrapper{
	height: 50px;
	width: 100%;
	box-shadow: 0px 0px 4px 0px #00000040;
	border-radius: 5px;
	
}

.product-box img{
	height: 80%;
	object-fit: contain;
	max-width: 120px;
    width: 106px;

}
.product-box p{
font-weight: 500;
}
.card-box-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	gap: 10px;
}
.card-box-wrapper img {
width: 22%;
	object-fit: contain;
}
.more-services{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.key-points{
	width: 320px;
	padding: 10px;
	box-shadow: 0px 0px 4px 0px #00000040;
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 15px;
font-weight: 500;
line-height: 20px;
text-align: left;

text-decoration-skip-ink: none;
color: #262626;
}
.logo-wrap p{
	font-family: Rubik;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #EC4E1E;

	
}
.rating-box img{
	width: 18px;
	height: 18px;
}
.logo-wrap{
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 5px;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
}
.key-points img{
	width: 25px;
	object-fit: contain;
	display: inline;
	margin-right: 10px;
	
}
.width-less{
	height: 75% !important;
}
@media (max-width: 1023px) {
	.page-2-wrapper {
padding: 0px 5px;
	}
	.store-title-wrapper h3{
		font-size: 20px;
	}
	.rating-box p{
		font-size: 18px;	
	}
	.product-wrapper h6 {
		font-size: 20px;

	}
	.detail-contanier{
		width: 100% !important;
		padding: 0px !important;
		gap: 14px;
	}
	.store-timing-container {
		width: 100%;
	}
	.store {
		width: 100%;
		padding: 0px;
		gap: 17px;
	}
	.store-info {
		gap:5px
	}
	.store-info p {
        font-size: 17px;
        line-height: 17.48px;
    }
	.store-info img {
        height: 28px;
        width: 28px;
    
	}
	.page-2-container {
		background-color: white;
	}
	.store-timing {
		padding: 10px;
		gap: 7px;
	}
	.store-timing h3{
		font-size: 23px;
		line-height: 23px;
	}
	.store-timing p {
		font-size: 15px;
		font-weight: 400;
		line-height: 15px;
	}
	.store-timing label {
		font-size: 18px;
		}
	.logos img {
		object-fit: contain;
		
        width: 100%;
			}
	.logo-wrap p {
		font-size: 19px;
			}
	.card-box-wrapper {
		gap: 5px;
		height: 45px;
		
	}
	.card-box-wrapper img {
		width: 14%;
	}
	.card-box-wrapper h6{
		font-size: 16px;		
	}
	.payments-wrapper h6{
		font-size: 20px;	
	}
	.more-services h6{
		font-size: 20px;
	}
	.product-container {
		width: 100%;
		height: 100%;
		display: flex
	;
		flex-wrap: nowrap;
		gap: 10px;
		padding: 10px;
		overflow: auto;
	}
	.product-container::-webkit-scrollbar {
		display: none; /* Hides scrollbar in Webkit browsers */
	  }
}
@media (max-width: 768px) {
	.page-2-wrapper {
padding: 0px 5px;
	}
	.store-title-wrapper h3{
		font-size: 20px;
	}
	.rating-box p{
		font-size: 18px;	
	}
	.product-wrapper h6 {
		font-size: 20px;

	}
	.detail-contanier{
		width: 100% !important;
		padding: 0px !important;
		gap: 14px;
	}
	.store-timing-container {
		width: 100%;
	}
	.store {
		width: 100%;
		padding: 0px;
		gap: 17px;
	}
	.store-info {
		gap:5px
	}
	.store-info p {
        font-size: 17px;
        line-height: 17.48px;
    }
	.store-info img {
        height: 28px;
        width: 28px;
    
	}
	.page-2-container {
		background-color: white;
	}
	.store-timing {
		padding: 10px;
		gap: 7px;
	}
	.store-timing h3{
		font-size: 23px;
		line-height: 23px;
	}
	.store-timing p {
		font-size: 15px;
		font-weight: 400;
		line-height: 15px;
	}
	.store-timing label {
		font-size: 12px;
		}
	.logos img {
		object-fit: contain;
		
		width: 100%;
			}
	.logo-wrap p {
		font-size: 12px;
			}
	.card-box-wrapper {
		gap: 5px;
		height: 40px;
		
	}
	.card-box-wrapper img {
		width: 17%;
	}
	.card-box-wrapper h6{
		font-size: 16px;		
	}
	.payments-wrapper h6{
		font-size: 16px;	
	}
	.more-services h6{
		font-size: 20px;
	}
}
@media (max-width: 425px) {
	.store-title-wrapper h3 {
        font-size: 18px;
    }
	.rating-box p {
        font-size: 15px;
    }
	.product-wrapper h6 {
        font-size: 16px;
    }
	.payments-wrapper h6 {
        font-size: 16px;
    }
	.more-services h6 {
        font-size: 16px;
    }
	.store-info img {
        height: 22px;
        width: 22px;
    }
	.page-width{
        padding: 7px 10px !important;
    }
	.product-container {
		padding: 5px
	}
	.detail-contanier{
		width: 100% !important;
		padding: 0px !important;
		gap: 10px;
	}
	.page-width {
        padding: 6px 9px;
    }
	.store-timing-container {
		width: 100%;
	}
	.store {
		width: 100%;
		padding: 0px;
		gap: 10px;
	}
	.store-info {
		gap:5px
	}
	.store-info p {
		font-size: 12px;
		line-height: 13.48px;
	}
	.page-2-container {
		background-color: white;
	}
	.store-timing {
		padding: 10px;
		gap: 7px;
	}
	.store-timing h3{
		font-size: 18px;
		line-height: 18px;
	}
	.store-timing p {
		font-size: 12px;
		font-weight: 400;
		line-height: 12px;
	}
	.store-timing label {
		font-size: 11px;
		}
	.logos img {
		object-fit: contain;
	
		width: 100%;
			}
	.logo-wrap p {
		font-size: 12px;
			}
	.card-box-wrapper {
		gap: 5px;
		height: 30px;
		padding: 3px;
	}

	.card-box-wrapper h6{
		font-size: 11px;		
	}
	.key-points {
		width: 100%;}

		.store-open {
			width: 78px;
			font-size: 12px;
		}
}
@media (max-width: 375px) {

	
	.store-timing label {
		font-size: 9px;
		}

}