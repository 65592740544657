/* Global css */

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

/* src/index.css */

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

:root {
  --black: #1c1c1c;
  --white: #ffffff;
  --orange: #ec4e1e;
  --primary-font: "Rubik", sans-serif;
  --h1: normal normal 500 34px/1.4 var(--primary-font);
  --h2: normal normal 500 26px/1.3 var(--primary-font);
  --h3: normal normal 500 22px/1.3 var(--primary-font);
  --h4: normal normal 500 20px/1.3 var(--primary-font);
  --h5: normal normal 500 18px/1.3 var(--primary-font);
  --h6: normal normal 500 16px/1.3 var(--primary-font);
  --sup: normal normal 400 14px/1.4 var(--primary-font);
}

h1,
.h1 {
  font: var(--h1);
  margin: 0;
}

h2,
.h2 {
  font: var(--h2);
  margin: 0;
}

h3,
.h3 {
  font: var(--h3);
  margin: 0;
}

h4,
.h4 {
  font: var(--h4);
  margin: 0;
}

h5,
.h5 {
  font: var(--h5);
  margin: 0;
}

h6,
.h6 {
  font: var(--h6);
  margin: 0;
}

p {
  font: var(--sup);
}

body {
  font-family: var(--primary-font);
}

img,
svg {
  max-width: 100%;
  display: block;
}

.order__status__final {
  padding: 50px;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(236 78 30 / 50%);
  border-radius: 0px;
  cursor: pointer;
}

/* Base css */
.product-grid {
  display: flex;
}

.hm__prod__slider-title.best__seller__item {
  justify-content: center;
  margin: 0 0 30px;
}

.padding-lr20 {
  padding: 0 20px;
}

.padding-top {
  padding-top: 20px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.margin-top {
  margin-top: 20px;
}

.pd-lr {
  padding: 0 20px;
}

.discount__price {
  color: var(--orange);
}

.font600 {
  font-weight: 600;
}

.flex-column {
  flex-direction: column;
}

.gap20 {
  gap: 20px;
}

.gap10 {
  gap: 10px;
}

.grey {
  color: #8e8e8e;
}

.gap5 {
  gap: 5px;
}

button {
  cursor: pointer;
}

.flex-start {
  display: flex;
  align-items: start;
  justify-content: start;
}

.page-width {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  /* padding: 0 20px 10px; */
  padding: 0 20px 5px;
}

.hidden {
  display: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header__searchbar {
  position: relative;
}

.focuse__items {
  display: none;
}

.focuse__items.active {
  position: absolute;
  background: #ffff;
  width: 100%;
  display: block;
  z-index: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-bottom: 5px;
}

ul.suggestions-list {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 10px;
  color: #000;
  max-height: 400px;
  overflow: auto;
}

ul.suggestions-list a li {
  color: #000;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.header__searchbar svg {
  width: 12px;
  height: 12px;
}

.header__searchbar > svg {
  width: 18px;
  height: 18px;
}

ul.suggestions-list a li:hover {
  color: #ec4e1e;
}

.slick-dots li button:before {
  font-size: 9px;
  color: #ec4e1e;
}

.slick-dots li.slick-active button:before {
  color: #ec4e1e;
  opacity: 1;
}

.slick-dots li {
  margin: 0 !important;
}

ul li a {
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
}

ul li a:hover {
  text-decoration: none;
}

.desktop-img {
  display: none !important;
}

.mobile-img {
  display: block !important;
}

.ban__container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ban__banner-heading {
  padding: 0 10px;
}

.ban__banner-heading h2 {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0;
}

.compare_price s {
  color: #8e8e8e;
}

.logolist__container {
  padding: 0 20px;
  overflow: hidden;
}

.logolist__container .slick-prev:before,
.logolist__container .slick-next:before {
  display: none;
}

.ban__container.amazing__brand img.motion-reduce {
  height: 100%;
  border-radius: 25px;
  object-fit: inherit;
  object-position: center;
}

.prod__container-2 .card-item {
  background: #fff;
}

.prod__container-2 .hm__prod__slider-title {
  padding: 20px 25px;
}

.hm__prod__desktop__arrow a {
  font: var(--h5);
  /* color: var(--orange); */
  font-weight: 400;
  color: #fff; /*theme*/
}

.hm__prod__slider-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.width-100 {
  max-width: 100%;
  padding: 0;
}

a {
  text-decoration: none;
}

.bg-color-black {
  background-color: #000000;
}

/* privacy policy */
.privacy-policy.page-width,
.refund-policy,
.shipping-policy,
.terms-condition {
  max-width: 900px;
  padding: 20px;
  background-color: #fff; /*theme*/
  margin-top: 15px; /*theme*/
  border-radius: 15px;
}

.contact__page {
  gap: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  background-color: #fff; /*theme*/
  border-radius: 15px;
  margin-top: 15px; /*theme*/
}

.privacy-policy.page-width div,
.refund-policy div,
.refund-policy span,
.shipping-policy div,
.shipping-policy span,
.terms-condition div,
.terms-condition span,
.privacy-policy.page-width span {
  border: none !important;
  color: #000 !important;
}

.privacy-policy.page-width:first-child span {
  display: none !important;
}

.privacy-policy.page-width h2,
.refund-policy h2,
.shipping-policy h2,
.terms-condition h2 {
  text-align: center;
}

/* LoadingCard.css */
.movie--isloading {
  height: fit-content;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
}

.loading-image.card-image {
  padding: 10px;
}

.movie--isloading .loading-image {
  height: 190px;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: 250px;
  animation: shine-loading-image 2s infinite ease-out;
  width: 100% !important;
}

.movie--isloading .loading-content {
  background: #f7f7f7;
  padding: 10px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.movie--isloading .loading-text-container {
  flex: 1;
}

.movie--isloading .loading-main-text {
  height: 10px;
  width: 65%;
  margin-bottom: 10px;
  background: #ececec;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-size: 250px;
  border-radius: 10px;
  animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-sub-text {
  height: 10px;
  width: 50%;
  background: #ececec;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-size: 250px;
  border-radius: 10px;
  animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-btn {
  width: 60px;
  height: 25px;
  background: #ececec;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-size: 250px;
  border-radius: 3px;
  animation: shine-loading-container-items 2s infinite ease-out;
}

@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }

  40%,
  100% {
    background-position: 208px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.loader .collection__wrapper .product-card {
  padding: 0;
}

.product-card {
  cursor: pointer;
}

.loader .product-card-container {
  display: flex;
  justify-content: space-between;
  /* Adjust as needed */
  gap: 20px;
  /* Adjust gap between cards */
}

.loader .card-item {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.loader {
  margin: 0 10px 0 0;
  padding: 0;
}

/* quantity selector css */

.quantity__selector {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  border: 1px solid #8d8d8d;
  max-width: 150px;
  border-radius: 4px;
  background-color: #fff; /*theme*/
}

.quantity__selector .input__value {
  padding: 0;
}

.quantity__selector .input__value .quantity__input {
  font: var(--h6);
}

input {
  border: 0.6px solid #8d8d8d;
  padding: 10.4px;
  border-radius: 6px;
}

.quantity__selector div {
  padding: 11px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.plus__btn {
  border-left: 0.6px solid #8d8d8d;
}

.minus__btn {
  border-right: 0.6px solid #8d8d8d;
}

.quantity__selector img {
  height: 16px;
  width: 100%;
}

/* pincode selector css */

.pincode__container {
  display: flex;
  flex-direction: column;
}

.pincode__validator__item {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
  width: 100%;
  gap: 10px;
}

.pincode__validator__item form {
  display: flex;
  width: 100%;
  gap: 15px;
}

.pincode__validator__item input {
  font: var(--sup);
  letter-spacing: 1px;
  width: 100%;
}

.pincode__submit {
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
  border: none;
  background: var(--orange);
  color: var(--white);
  font: var(--sup);
}

.pincode__desc {
  display: flex;
  justify-content: start;
  margin: 10px 0 0;
}

.pincode__desc img {
  width: 20px;
  margin-right: 5px;
}

.pdp_other_section .pdp_other_label {
  color: var(--black);
  font: var(--h6);
  margin: 0 0 10px;
  color: #fff; /*theme*/
}

.dot__line {
  border-bottom-width: 1.5px;
  border-bottom-style: dashed;
  border-bottom-color: #aaaaaa;
}

.pincode__text {
  font: var(--sup);
}

/* submit buttons css */
.submit__buttons {
  max-width: 450px;
  width: 100%;
  gap: 25px;
}

.btn_primary {
  background: var(--orange);
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 6px;
  color: var(--white);
  font: var(--h6);
  font-weight: 400;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  min-width: 160px;
}

.submit__buttons .btn_primary {
  flex: 1;
}

.submit__buttons .btn_primary[disabled] {
  cursor: no-drop;
  opacity: 0.5;
}
.pincode__validator__item .pincode__submit[disabled] {
  cursor: no-drop;
  opacity: 0.5;
}
.btn_primary img {
  width: 24px;
}

.submit__buttons__container {
  margin: 30px 0 0;
}

.hm__image.page-width {
  padding: 20px;
}

.plus__btn[disabled] {
  opacity: 0.2;
  cursor: not-allowed;
}

.minus__btn[disabled] {
  opacity: 0.2;
  cursor: not-allowed;
}

/* Product Description css */
.product__description-item {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px dashed #5b5b5b;
  margin: 25px 0 0;
}

.product__description-item details.product__desc summary {
  font-weight: 400;
  position: relative;
  padding: 0 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product__description-item details.product__desc h2 {
  font: var(--h5);
  color: #fff; /*theme*/
}

.product__description-item details.product__desc summary::before {
  content: none;
}

.product__description-item details.product__desc summary svg {
  width: 15px;
}

.product-image-wrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  margin-right: 15px;
  border-top-left-radius: 15px;
}

.prod__img img {
  width: 80%;
  height: 80%;
  object-fit: scale-down;
  position: absolute;
  inset: 0;
  margin: auto;
  opacity: 0;
}

.product-image-wrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.pdp_icon_badge ul {
  display: flex;
  gap: 20px;
}

.pdp_icon_badge ul li {
  flex: 1;
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #ffffff;
  border-radius: 10px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdp_icon_badge ul li img {
  width: 40px;
  height: 42px;
  object-fit: scale-down;
  margin: 0 auto 15px;
}

.pdp_icon_badge {
  margin: 30px 0 0;
}

.pdp_icon_badge ul li p {
  font: var(--sup);
  font-weight: 400;
  color: var(--orange);
  text-align: center;
}

.pdp_coupon_wrap ul {
  display: flex;
  gap: 20px;
  overflow: auto;
  padding: 10px 5px 20px;
}

.thumbnail {
  max-width: 111px;
  width: 100%;
}

.pdp_coupon_wrap ul li {
  width: 100%;
  flex-shrink: 0;
  max-width: 360px;
}

.card-image a {
  height: 100%;
  position: absolute;
}

.coupon_main_card {
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 16px 0px #0000001a;
  min-height: 120px;
  border-radius: 10px;
  padding: 10px 30px;
  position: relative;
  overflow: hidden;
  background-color: #fff; /*theme*/
}

.apply_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.apply_btn a {
  color: var(--orange);
  font: var(--sup);
}

.tm_cnt {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.tm_cnt p,
.tm_cnt a {
  font-size: 10px;
  cursor: pointer;
  color: var(--black);
}

.tm_cnt p:hover,
.tm_cnt a:hover {
  color: var(--orange);
}

.coupon_img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.coupon_img img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.coupon_content {
  flex: 1;
}

.coupon_content p {
  margin: 5px 0;
  color: #5a5a5a;
}

.coupon_content span {
  font-size: 10px;
  max-width: 130px;
  width: 100%;
  display: block;
  color: #8c8c8c;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.coupon_main_card:before,
.coupon_main_card:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 13px 0px inset,
    rgba(17, 17, 26, 0.01) 0px -4px 15px 0px;
  left: 0;
  background: #ffffff;
}

.coupon_main_card:before {
  left: -10px;
}

.coupon_main_card:after {
  right: -10px;
  left: auto;
}

.pdp_coupon_wrap ul::-webkit-scrollbar {
  height: 5px;
}

.pdp_coupon_wrap ul::-webkit-scrollbar-thumb {
  background: #ececec;
}

.pdp_coupon_wrap ul::-webkit-scrollbar-track {
  background: transparent;
}

.blogs__filter__buttons::-webkit-scrollbar {
  /* height: 5px; */
  display: none;
}

.breadcrumbs {
  display: flex;
  gap: 5px;
  color: #fff;
}
.breadcrumbs span {
  display: flex;
  gap: 5px;
}

.breadcrumbs span img {
  width: 7px;
  height: auto;
  transform: rotate(0deg);
}

.offer_coupon {
  margin: 20px 0;
}

.main_address {
  max-width: 520px;
  width: 100%;
  box-shadow: 0px 0px 15px 0px #0000000f;
  border-radius: 10px;
  padding: 15px 20px;
}

.address_title h6 {
  margin: 0 0 12px;
}

.address_location {
  display: flex;
  align-items: center;
}

.address_location img {
  width: 22px;
  height: auto;
  margin-right: 10px;
}

.address_location p {
}

.address_book_pdp {
  margin: 20px 0;
}

/* .product__description-item details.product__desc summary::after {
  content: '';
  position: absolute;
  top: 10%;
  right: 10px;
  width: 15px;
  height: 15px;
  background: url('http://localhost:3000/static/media/arrowTop.1d8e893c711f193675bcfb11ffeb715c.svg') no-repeat center center;
  background-size: contain;
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s;
} */

.product__description-item details.product__desc[open] summary::after {
  transform: rotate(180deg);
}

.flex {
  display: flex;
  align-items: center;
}

.green {
  color: #077c46 !important;
}

/* Remove default markers from <summary> elements */
details summary {
  list-style: none;
  cursor: pointer;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::before {
  content: "";
}

details[open] summary::before {
  content: "";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hm__product-container.sports__supplements .slider_bg_color {
  background: #ffe6d6;
  padding-bottom: 20px;
  padding-top: 20px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.disabled {
  background: #8d8b8b !important;
  color: #fff;
}

.orange_color {
  color: var(--orange);
}

.logolist__wrapper .slick-dots li button:before {
  display: none;
}

.logolist__item {
  height: 120px;
  max-width: 120px;
  margin: 0 auto;
  display: block !important;
}

.logolist__item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sports_supple {
  margin: 10px 0 40px 0;
}

.slider_container {
  /* max-width: 1452px; */
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.slick-list .slick-track .slick-slide > div {
  line-height: 0;
}

.section_space {
  margin: 10px 0 40px;
}

.banner_sl_container {
  /* max-width: 1452px; */
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.cm_banner_slider .main__image {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  padding: 0 0 46%;
  margin-right: 10px;
}

.cm_banner_slider .main__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: inherit;
}

.cm_banner_slider .slick-list .slick-track {
  display: flex;
  margin: 0;
}

.cm_banner_slider .slick-list .slick-track .slick-slide {
  padding-right: 0px;
}

.slick-slider.cm_banner_slider.cm_arrow_side.slick-initialized {
  padding: 0 5px;
}

.hm__icon__grid-wrapper {
  padding: 5px 0px 10px 0px;
  background-color: var(--orange);
}

.extra__product__card-item h3 {
  text-align: center;
  margin: 10px 0;
}

.extra__product__card-item .extra__product__card-inner p {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 10px;
  padding-left: 4%;
  padding-top: 4%;
}
.maintenance__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  gap: 20px;
}

.maintenance__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.maintenance__btn {
  background: #ffe6de;
  color: #ec4e1e;
  padding: 10px 15px;
  width: 160px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 600;
}

.maintenance__logo {
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.maintenance__logo img {
  display: flex;
  width: 100%;
  max-width: 150px;
  margin: 8px auto;
}

.maintenance__container img {
  max-height: calc(100vh - 180px);
  height: 100%;
}

.hm__icon__content p {
  color: var(--white);
  font: var(--h6);
  font-weight: 400;
}

.hm__icon__item {
  width: 25%;
  text-align: center;
}

.hm__icon__item .hm__icon__content {
  margin: 15px 0 0;
}

.hm__image__wrapper__sec {
  margin: 0 0 40px;
}

.logolist__wrapper,
.infinity_brand_icon {
  width: 100%;
}

.infinity_brand_icon {
  padding: 10px 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background-color: #fff;
}

.cm_arrow_side .slick-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0px, -50%);
  right: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 3px 1px 4px 0px #00000040;
  background-color: #ffff;
  border: 2px solid var(--orange);
  cursor: pointer;
  margin: 0;
  font-size: 0px;
  background-image: url("../src/assests/image/next_arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 29%;
}

.cm_arrow_side .slick-arrow:before {
  content: none;
}

.cm_arrow_side .slick-arrow.slick-prev {
  left: -5px;
  background-image: url("../src/assests/image/prev_arrow.svg");
}

.slick-slider.cm_banner_slider.cm_arrow_side button.slick-arrow.slick-prev {
  left: -10px;
}
.slick-slider.cm_banner_slider.cm_arrow_side button.slick-arrow.slick-next {
  right: -10px;
}

.cm_arrow_side .slick-arrow img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.card_variant_main {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.card_variant_main.active_var {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.card_var_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 18px 15px;
  background-color: #ffffff;
  border-bottom: 1px dashed #5b5b5b;
}

.card_var_title p {
  font-weight: 600;
  font-size: 16px;
  font-family: var(--primary-font);
}
.close_btn {
  cursor: pointer;
}

.extra__product__card-item .extra__product__card-inner span {
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  font-size: 22px;
  font-family: "Rubik";
}

.card_var_body {
  padding: 5%;
  flex: 1;
  overflow: auto;
}

.card_var_footer {
  padding: 4%;
  box-shadow: 0px -5px 17px 0px #00000040;
  border-radius: 10px 10px 0;
}

.view_product_btn {
  text-align: center;
  margin: 15px 0 0;
}

.card_var_footer .product_btns {
  margin: 0;
}

.view_product_btn a {
  font: var(--h6);
  text-transform: uppercase;
  color: var(--black);
}

.card_var_items {
  border: 1px solid #6d6d6d;
  border-radius: 7px;
  padding: 0 0 15px;
}

.card_var_items p {
  background: #ec4e1e33;
  padding: 8px 10px;
  font-weight: 600;
  font-size: 14px;
}

.card_var_items .product_price {
  margin: 15px 0 0;
  padding: 0 10px;
}

.card_var_select .css-13cymwt-control {
  background: #f5f5f5;
}

.card_var_select .css-1dimb5e-singleValue {
  font: var(--sup);
}

.card_var_select .css-qbdosj-Input {
  font: var(--sup);
}

.card_var_select .css-d7l1ni-option {
  background: #ffd5c8;
}

.card_var_select .css-tr4s17-option {
  background: var(--orange);
}

.main__image img {
  height: 100%;
  object-fit: contain;
}

.pdp_pr_img_wrap {
  margin: 40px 0;
}

.pdp_banner_title {
  margin: 0 0 30px;
}

.pdp_img {
  margin: 0 0 20px;
}

.pdp_img img {
  border-radius: 15px;
  width: 100%;
}

.pdp_review_wrap .review_left {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 1px #00000033;
  border-radius: 10px;
  padding: 30px;
}

.write_star_icon {
  display: flex;
  gap: 5px;
}

.write_star_icon img {
  width: 20px;
}

.star_rating_icon h5 {
  margin: 0 0 10px;
  font-size: 26px;
  line-height: 1;
  width: 100%;
}

.star_rating_icon {
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.star_rating_icon > span {
  padding-left: 10px;
  color: #5c5c5c;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.add_review {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
.rating-container {
  width: 100%;
  padding: 20px 0 0 0px;
}

.pdp_review_wrap .review_right {
  width: 100%;
  margin: 25px 0 0;
}

.star_count_wrap {
  display: flex;
  padding: 10px 20px 20px;
  background: #f8f8f8;
  border: 1px solid #6f6f6f;
  border-radius: 8px;
  justify-content: space-between;
}

.star_bars {
  width: 75%;
}

.star_bars ul li {
  display: flex;
  align-items: center;
}

.star_bars ul li span {
  font: var(--h6);
  font-weight: 400;
}

.star_bars ul li img {
  width: 16px;
  margin: 0 4px;
}

.star_bar {
  background: #006d60;
  height: 9px;
  border-radius: 20px;
}

.star_bar.five_star {
  width: 100%;
  flex: 1;
}

.star_bar.four_star {
  width: 75%;
}

.star_bar.three_star {
  width: 50%;
}

.star_bar.two_star {
  width: 20%;
}

.star_bar.one_star {
  width: 10%;
}

.star_bars ul li:not(:last-child) {
  margin: 0 0 10px;
}

.total_rating {
  text-align: right;
  flex: 1;
}

.total_rating h3 {
  margin: 0 0 8px;
}

.total_star_count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.total_star_count img {
  width: 16px;
}

.total_rating h4 {
  font: var(--sup);
  margin: 10px 0 0;
}

.add_review h5 {
  margin: 0 0 10px;
}

.add_review p {
  color: #5a5a5a;
  letter-spacing: 0.4px;
  max-width: 370px;
  width: 100%;
}
.star_rating_icon p {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.add_review .btn_primary {
  margin: 10px 0 0;
  max-width: 430px;
  width: 100%;
  border: none;
}

.review_list_item {
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 10px;
  padding: 15px 20px;
}

.review_icon {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.rv_auther_star {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 2px 0 0;
}

.rv_icon_img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 10px;
}

.rv_icon_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rv_auther_star img {
  width: 16px;
}

.review_list_item:not(:last-child) {
  margin: 0 0 15px;
}

.btn_primary.btn_border_btn {
  background: transparent;
  border: 1px solid var(--orange);
  color: var(--orange);
}

.review_right .btn_primary.btn_border_btn {
  max-width: 430px;
  width: 100%;
  margin: 15px 0 0;
}

.pdp_review {
  margin: 60px 0 50px;
}

.slider_container .slick-list .slick-track {
  margin: 0;
}

.brand__details__filter {
  margin: 15px 0;
}

.card_var_items .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer span {
  display: none;
}

.card_var_items .css-13cymwt-control .css-1xc3v61-indicatorContainer svg path {
  fill: var(--orange);
}

.header__icon-link svg {
  width: 24px;
  height: auto;
}

.close {
  width: 15px;
  height: 15px;
}

.popup_main {
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 9999;
}

.popup_overlay {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 30%);
}

.popup_wraper {
  position: relative;
  max-width: 500px;
  width: calc(100% - 30px);
  background: var(--white);
  margin: auto;
  border-radius: 20px;
  padding: 30px 20px;
}

.pop_up_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
}

.cs_pop_close {
  width: 15px;
  margin: 0 0 0 auto;
  cursor: pointer;
}

.review_pop_body {
  text-align: center;
}

.input_field input,
.input_field textarea {
  border: 1px solid #8d8d8d;
  font: var(--sup);
  width: 100%;
  border-radius: 6px;
  padding: 10px 15px;
}

.input_field {
  text-align: left;
  margin: 0 0 15px;
}

.input_field label {
  display: block;
  font: var(--sup);
  padding: 0 0 4px;
}

.review_pop_body h5 {
  font-size: 22px;
  margin: 0 0 10px;
}

.review_pop_body p {
  color: #5a5a5a;
}

.review_form {
  max-width: 360px;
  margin: 40px auto 0;
  width: 100%;
}

.collection_filter_mob {
  max-width: 180px;
  position: sticky;
  box-shadow: 0px 5px 19px 1px #00000066;
  border-radius: 10px;
  bottom: 20px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 20px;
}

.collection_filter_mob h6 {
  font-size: 14px;
}

.collection_filter_mob img {
  width: 20px;
  height: auto;
}

.br_title_mob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  gap: 15px;
  border-bottom: 1px solid #6d6d6d;
}

.br_title_mob img {
  width: 20px;
}

.close_filter {
  margin: 0 0 0 auto;
}

.br_title_mob h4 {
  margin: 0;
  font-size: 16px;
}

.sold_out_label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #ffffff;
  padding: 12px 30px;
  border-radius: 10px;
}
.play_label {
  position: absolute;
  width: 50px; /* adjust the width and height as needed */
  height: 50px;
  z-index: 1;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sold_out_label h2 {
  font-size: 30px;
  color: var(--orange);
  white-space: nowrap;
}

.product_review .pdp_rv_stars {
  gap: 5px;
  margin: 0;
}

.product_review .pdp_rv_stars img {
  width: 16px;
}

.page_loading img {
  width: 400px; /*theme*/
  height: auto;
}

.page_loading {
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*theme*/
  background-color: radial-gradient(
    116.76% 80.64% at 50% 50%,
    #005e9e 0%,
    #011228 100%
  );

  /* background-color: var(--white); */
}

.address_card_main h6 {
  margin: 0 0 15px;
}

.address_card_main address {
  font: var(--sup);
  line-height: 1.5;
}

.address_action {
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  gap: 15px;
}

.link_btn {
  color: var(--orange);
  background: transparent;
  border: none;
  font: var(--sup);
  font-weight: 500;
}

.account__menu__item:before,
.account__menu__item:after {
  content: "";
  position: absolute;
  box-shadow: 3.5rem 0.5rem 0 0 #0000;
  height: 50px;
  opacity: 1;
  right: 0;
  top: -49px;
  transform: rotate(43deg);
  width: 50px;
  border-radius: 80px;
}

.account__menu__item.active:after,
.account__menu__item.active:before {
  box-shadow: 35px 5px 0 0 var(--orange);
}

.account__menu__item.active:after {
  top: 100%;
  transform: rotate(-54deg);
  right: 0;
}

.coupon_code {
  border-top: 1px dashed #6b6b6b;
  padding: 20px 0 0;
}

.coupon_code_tc {
  padding: 15px 0 0;
  margin: 15px 0 0;
  border-top: 1px solid #e5e5e5;
}

.coupon_code_label h5 {
  border: 1px solid #333333;
  display: inline-flex;
  padding: 4px 7px 1px;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 0 10px;
}

.coupon_code_label p,
.coupon_code_tc ul li,
.coupon_code_tc span {
  color: #5b5b5b;
}

.coupon_code_tc h5 {
  margin: 0 0 10px;
  font-size: 16px;
}

.coupon_code_tc ul li,
.coupon_code_tc span {
  font: var(--sup);
  list-style: inside;
}

.coupon_code_tc ul li:not(:last-child) {
  padding: 0 0 8px;
}

.error_msg {
  display: block;
  width: 100%;
  color: #c51818 !important;
  margin: 5px 0 0;
}

.order__info .not_data_found_wrap {
  padding: 0;
  border: 1px solid #dee2e7;
  border-radius: 10px;
  padding: 30px;
}

.order__info .not_data_found_wrap .img_found_wrap {
  max-width: 300px;
}

.order__info .not_data_found_wrap .not_found_text {
  margin: 15px 0 0;
  text-align: center;
}

.order__info .not_data_found_wrap .not_found_text h5 {
  font-size: 25px;
  font-weight: 600;
  color: var(--black);
  margin: 0 0 20px;
}

.header__icons .modal {
  padding: 0;
}

.not_available_wrap {
  position: absolute;
  inset: 0;
  background: rgb(255 255 255 / 50%);
  z-index: 1;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quantity__selector input.quantity__input {
  background: transparent;
}

.slider_container.cm_arrow_side .not_available_wrap {
  position: absolute;
  inset: 0;
  background: rgb(255 255 255 / 50%);
  z-index: 1;
  border-radius: 10px;
  width: 93%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.opacity__main__container.active {
  background: #4b4c4c96;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 12;
  background-image: url(./assests/image/loader_icon_white.gif);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 90px;
}

.product_not_available {
  position: relative;
}

.not_available_wrap .sold_out_label {
  padding: 10px 15px;
  border-radius: 5px;
}

.not_available_wrap .sold_out_label h2 {
  font-size: 18px;
  line-height: 1;
}

.toast_msg {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 9;
}

.toast_msg .toast_mesg_wrap {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  margin: 0 auto;
  max-width: 400px;
  width: calc(100% - 40px);
  box-shadow: 0px 0px 4px 1px #00000033;
  gap: 20px;
}

.toast_msg .toast_mesg_wrap img {
  width: 22px;
  height: auto;
}

.toast_mesg_wrap .btn_primary {
  min-width: unset;
  padding: 7px 30px;
  margin: 0 0 0 auto;
}

.toast_msg .toast_mesg_wrap h4 {
  font-size: 18px;
}

.app_annouce_text {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding-left: 15px;
}

.app_annouce_text img {
  width: 17px;
}

.app_anouncement {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ffe6d6;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 1px 4px 0px #00000040;
  transition: transform 0.5s ease;
  transform: translateY(0);
  background-image: url(../unFITSnowFlake.gif); /*theme*/
}

.app_anouncement.slide-up {
  transform: translateY(-100%);
}

.app_anouncement .close_icon {
  width: 15px;
  flex-shrink: 0;
}

.app_anouncement p {
  font-size: 13px;
  font-weight: 400;
  flex: 1 1;
  line-height: 1.4;
  padding: 0 10px 0 12px;
}

.app_anouncement .btn_primary {
  min-width: unset;
  font-size: 12px;
  padding: 8px 15px;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

/* PDP Review css */

.rating span {
  width: 60px;
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
}

.progress-bar {
  flex: 1;
  height: 10px;
  background-color: #f8f8f8;
  border-radius: 6px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #006d60;
  border-radius: 5px;
}
.rating-container {
  width: 100%;
}

.rating {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 0 16px 0;
  width: 100%;
}

.rating img {
  display: flex;
  width: 20px;
}

.star__icons__review {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.pdp_review_wrap {
  display: flex;
  justify-content: space-around;
}

.review_lefts {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.review_lefts {
  background: #f8f8f8;
  border-radius: 6px;
  border: 1px solid #000;
  width: 50%;
}

.pdp_review_wrap {
  gap: 20px;
}
.pdp_review_wrap .review_right {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-right: 50px;
  width: 50%;
  padding: 0 20px;
}

.pdp_review_wrap .review_right span {
  font-size: 18px;
  font-weight: 500;
}
/***** RESPONSIVE *******/

/* .maintenance__btn svg {
  transform: rotate(197deg);
  transition: transform 0.3s ease; /* Smooth transition for initial rotation */
/* } */

/* .maintenance__btn:hover svg {
  animation: rotate-circle 2s infinite linear;
}  */

/* @keyframes rotate-circle {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
} */

/* --- BEST SELLER CSS ----- */

/* 
.mega-menu-item [href="/blogs/blog-posts"] {
    /* display: none; */

.hm__prod__slider-title p {
  font: var(--h2);
  margin: 0;
  color: #ffffff; /*theme*/
}

.hm__prod__slider-title h2 {
  color: #ffffff; /*theme*/
}

.best__seller__item {
  margin: 0 auto;
}

.best__seller__item .product-card {
  width: calc(50% - 5px);
}

.best__seller__item .product-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 20px 10px;
  padding: 30px 0;
}
.best__seller__item .product-grid .card-wrapper {
  padding: 0;
}

.extra__product__card-item p.brandplacement__name {
  font: var(--h1);
}

.not_available_wrap .sold_out_label p {
  font-size: 18px;
  line-height: 1;
}

.sold_out_label p {
  color: #ec4e1e;
  color: var(--orange);
  font-size: 30px;
  white-space: nowrap;
  font: var(--h2);
}

.not_available_wrap .sold_out_label p {
  font-size: 18px;
  line-height: 1;
}

.sold_out_label p {
  font-size: 30px;
  color: var(--orange);
  white-space: nowrap;
}

.nav_sub_menu {
  margin-top: 10px;
}

/* .ticker-1 {
  position: relative;
  left: 0;
  animation: swap 15s linear infinite; /* Use swap animation with infinite loop */
/* white-space: nowrap; 
  overflow: hidden;
} */

.about-us a {
  color: #ec4e1e;
}
.ticker-container {
  position: relative;
  width: 100%;
  max-width: 1260px; /* Limit the width */
  overflow: hidden; /* Hide the overflow to prevent visible scrollbars */
  white-space: nowrap; /* Prevent the text from wrapping */
}

.header__announcement-wrapper {
  flex-direction: column;
  height: 100%;
  max-height: 25px;
  min-height: 25px;
  overflow: hidden;
  padding-top: 10px;
}

.ticker-1 {
  position: relative;
  animation: marquee 20s linear infinite; /* Smooth and infinite scrolling */
  white-space: nowrap; /* Keep the text in a single line */
}

img.backtop-image {
  width: 50px;
  height: auto;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start outside the viewport from the right */
  }

  100% {
    transform: translateX(-100%); /* Move out to the left of the viewport */
  }
}

/* Hide the scrollbar for WebKit browsers */
.ticker-container::-webkit-scrollbar {
  display: none;
}

/* Hide the scrollbar for Firefox */
.ticker-container {
  scrollbar-width: none;
}

.loadmore__btn img {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  position: relative;
  top: -30px;
}

@keyframes marquee {
  0% {
    transform: translateX(
      100%
    ); /* Start from outside the viewport on the right */
  }

  100% {
    transform: translateX(
      -100%
    ); /* Move the content to the left out of the viewport */
  }
}

.backtop-image {
  transition: opacity 0.3s ease-in-out;
}

.first-image {
  display: block;
}

.second-image {
  display: none;
}

button:hover .first-image {
  display: none;
}

button:hover .second-image {
  display: block;
}

.back-to-top button {
  position: fixed;
  bottom: 75px;
  right: 30px;
  /* padding: 10px 20px; */
  font-size: 18px;
  background-color: #ffffff00; /* Background color as white */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.announcement__content.desktop {
  display: block;
}
.announcement__content.mobile {
  display: none;
}

.white_heading {
  color: #ffffff;
}

@media (min-width: 768px) {
  .best__seller__item .product-card {
    width: calc(33% - 5px);
  }
}

@media (min-width: 768px) {
  .logolist__wrapper .loading-cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  [data-index="2"] .main__image {
    margin: 0;
  }

  .cm_banner_slider .main__image {
    margin-right: 10px;
  }
  .app_anouncement {
    display: none;
  }

  .logolist__wrapper .movie--isloading {
    height: 120px;
    width: 130px;
  }

  .logolist__wrapper .movie--isloading .loading-content {
    display: none;
  }

  .extra__card__item .slick-slider.page-width {
    max-width: 933px;
    overflow: hidden;
  }

  .collection_filter_mob,
  .br_title_mob {
    display: none;
  }

  .cart__final__container.orderType {
    margin: 0 0 15px;
  }
}

@media (min-width: 991px) {
  .desktop-img {
    display: block !important;
  }

  .cm_banner_slider .main__image {
    margin-right: 0px;
  }

  .mobile-img {
    display: none !important;
  }

  .brand__product-grid .list__view .card-content {
    max-width: 250px;
  }

  .brand__product-grid.grid__view.list__view {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .review_lefts {
    display: flex;
    flex-direction: row;
    padding: 0px;
  }
  .rating-container {
    width: 100%;
    padding: 20px 0 0 20px;
  }
  .cm_banner_slider .slick-list .slick-track .slick-slide {
    padding-right: 15px;
  }
}

@media (min-width: 1024px) {
  .extra__card__item .slick-slider.page-width {
    max-width: 933px;
    overflow: hidden;
  }
}

@media (min-width: 1024px) {
  .best__seller__item .product-card {
    width: calc(25% - 10px);
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .best__seller__item .product-card {
    width: calc(20% - 10px);
    margin-bottom: 0px;
  }
}

@media (min-width: 1440px) {
  .extra__card__item .slick-slider.page-width {
    max-width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 990px) {
  .brand__product-grid.grid__view.list__view {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .brand__product-grid .list__view .card-content {
    max-width: 250px;
  }

  :root {
    --h1: normal normal 500 30px/1.4 var(--primary-font);
    --h2: normal normal 500 22px/1.3 var(--primary-font);
    --h3: normal normal 500 20px/1.3 var(--primary-font);
    --h4: normal normal 500 18px/1.3 var(--primary-font);
    --h5: normal normal 500 16px/1.3 var(--primary-font);
    --h6: normal normal 500 14px/1.3 var(--primary-font);
  }

  .header__searchbar input {
    box-shadow: 0px 1px 4px 0px #00000040;
    border: none;
    border-radius: 10px;
  }

  .header__searchbar img.icon-search {
    left: 28px;
  }

  .brand__details__filter {
    margin: 20px 0 35px;
  }

  .product-image-wrap {
    flex-direction: column;
  }

  .product__thumbnails {
    padding: 10px;
  }

  .pdp_icon_badge ul {
    gap: 10px;
  }

  .pdp_icon_badge ul li {
    min-height: 110px;
    padding: 0 10px;
  }

  .pdp_icon_badge ul li img {
    width: 30px;
    height: 30px;
  }

  .pdp_icon_badge {
    margin: 30px 0 0;
  }
  .main_menuDrawer .mega-menu {
    display: block;
    position: unset;
    box-shadow: none;
    flex: 1;
    overflow: auto;
  }
  .main_menuDrawer .mega-menu .mega-menu-content {
    height: 100%;
  }
  .main_menuDrawer .mega-menu .mega-menu-section.left {
    width: 50%;
    padding: 10px 5px 10px 10px;
    background: rgb(142 142 142 / 10%);
  }
  .main_menuDrawer
    .mega-menu
    .mega-menu-section.left
    .main__mega__menu-content {
    margin: 0;
    max-height: unset;
  }

  .main_menuDrawer .mega-menu .mega-menu-section.right {
    width: 50%;
    min-width: inherit;
    background: transparent;
    overflow: auto;
  }
  .main_menuDrawer .mega-menu .mega-menu-section.right ul.top__brands {
    max-height: unset;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }

  .main_menuDrawer .mega-menu .mega-menu-section.right h3 {
    padding: 10px 10px 0;
  }

  .main_menuDrawer
    .mega-menu
    .mega-menu-section.right
    ul.top__brands
    .top__brands__item {
    padding: 5px;
    width: calc(50% - 5px);
  }
}

@media (max-width: 800px) {
  .slick-slider.product__thumbnails .slick-arrow.slick-next,
  .slick-slider.product__thumbnails .slick-arrow.slick-prev {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .header__announcement-bar {
    margin-top: 12px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .loader .product-card-container {
    flex-wrap: wrap;
  }

  .product__breadcrumbs {
    display: none;
  }
  .prod__imgs.mobile {
    margin-top: 15px;
  }
  .login__btn__mob svg {
    width: 25px;
    height: 25px;
  }

  .review_lefts {
    width: 100%;
  }

  .logolist__wrapper .loading-cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .logolist__wrapper .movie--isloading {
    height: 100px;
    width: 100px;
  }

  .extra__product__card-item h3 {
    text-align: center;
    margin: 20px;
  }

  .extra__product__card-item p {
    margin-bottom: 15px;
  }

  .logolist__wrapper .movie--isloading .loading-content,
  .hm__prod__desktop__arrow {
    display: none;
  }

  .loader .card-item {
    width: calc(100%);
  }

  .brand__img img {
    max-height: 140px;
    height: 140px;
  }

  .more__view {
    color: var(--orange);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding: 15px 0 5px;
    font-weight: 500;
  }

  /* slider banner arrow disable in mobile */
  /* .hm__slider__container .slick-arrow.custom-prev,
  .hm__slider__container .slick-arrow.custom-next,
  .slick-arrow.hm__prod-prev,
  .slick-arrow.hm__prod-next,
  .header__announcement-bar {
    display: none !important;
  } */

  .brand__grid_heading.ban__banner-heading {
    display: flex;
    justify-content: space-between;
  }

  .ban__banner-heading h2 {
    font-size: 16px;
  }

  .hm__icon__content p {
    font-size: 12px;
  }

  .hm__slide__item img {
    border-radius: 10px;
  }
  .hm__slide__item video {
    border-radius: 10px;
  }

  .hm__slider__wrapper {
    padding: 20px 0 0;
  }

  .section_space {
    margin: 30px 0;
  }

  .banner_sl_container,
  .slider_container {
    padding: 0 5px;
  }

  .cm_banner_slider .main__image {
    border-radius: 10px;
  }

  .card-content div {
    -webkit-line-clamp: 2;
  }

  :root {
    --h1: normal normal 500 28px/1.4 var(--primary-font);
    --h2: normal normal 500 16px/1.3 var(--primary-font);
    --h3: normal normal 500 14px/1.3 var(--primary-font);
    --h4: normal normal 500 12px/1.3 var(--primary-font);
    --h5: normal normal 500 14px/1.3 var(--primary-font);
    --h6: normal normal 500 14px/1.3 var(--primary-font);
  }

  .sports_supple {
    margin: 40px 0 30px;
  }

  .card-image {
    padding: 0 0 88%;
  }

  .product_price {
    margin: 5px 0 0;
  }

  .product_btns {
    margin-top: 10px;
  }

  .card-wrapper .card-item .product__discount {
    font-size: 12px;
  }

  .actual_price {
    font-size: 14px;
  }

  .card-content {
    padding: 0 10px 10px;
  }

  .hm__icon__grid-wrapper {
    padding: 15px 0px;
  }

  .hm__icon__image img {
    width: 45px;
  }

  .slider_container {
    margin: -10px 0;
  }

  .product__color__item {
    display: flex;
    flex-wrap: wrap;
  }

  .product__color__item .product-card {
    width: 50%;
    padding: 5px 0;
  }

  .hm__image__wrapper__sec {
    margin: 0 0 30px;
  }

  .logo__item {
    margin: 0 0 15px;
  }

  .logo__item img {
    max-width: 120px;
  }

  .footer__logoes {
    gap: 8px;
  }

  .footer__copyright.caption {
    flex-direction: column;
    gap: 10px;
  }

  .footer__container .playstore__item img,
  .apple__item img {
    max-width: 110px;
  }

  .footer__logo-items {
    padding: 30px 0 0px;
    width: 100%;
  }

  .footer__about-us {
    max-width: 100%;
    order: 3;
    flex: 1;
    width: 100%;
  }

  .footer__menus-items {
    order: 2;
    width: 100%;
    max-width: 50%;
  }

  .footer__subscribe-item {
    max-width: 50%;
    width: 100%;
    order: 4;
    padding: 0 0;
  }
  .footer__about__content {
    font-size: 12px;
    letter-spacing: 0.5px;
    font-family: "Rubik";
  }

  footer h5 {
    padding-bottom: 10px;
    font-size: 14px;
  }

  footer li {
    padding-bottom: 8px;
  }

  .footer__wrapper li a {
    font-size: 12px;
    text-decoration: underline;
  }

  .footer__about-us p {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding-bottom: 10px;
  }

  .contact_us-content li a {
    font-size: 10px;
  }

  .footer_social ul {
    gap: 13px;
    justify-content: center;
    margin: 10px 0 0 0;
  }

  .footer_social ul img {
    height: 20px;
    width: 20px;
  }

  .cm_arrow_side .slick-arrow.slick-prev {
    left: -2px;
  }

  .cm_arrow_side .slick-arrow {
    right: -2px;
    width: 32px;
    height: 32px;
  }

  .cm_banner_slider .main__image {
    margin-right: 10px;
  }

  .card_var_footer,
  .card_var_title,
  .card_var_body {
    padding: 10px;
  }

  .close_btn {
    width: 15px;
  }

  .product_atc_btn img {
    width: 15px;
    height: 15px;
  }

  .view_product_btn {
    margin: 10px 0 0;
  }

  .card_var_items {
    padding: 0 0 10px;
  }

  .card_var_items:not(:last-child) {
    margin: 0 0 10px;
  }

  .card_var_items .product_price {
    margin: 10px 0 0;
    padding: 0 5px;
  }

  .card_var_items .product_price .price {
    gap: 4px;
  }

  .card_var_items .actual_price {
    font-size: 12px;
  }

  .card_var_items .compare_price,
  .card_var_items .product__discounts {
    font-size: 10px;
  }

  .brand__product-list {
    padding: 0;
  }

  .brand__filters {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 11;
    background: #ffffff;
    inset: 0;
    opacity: 0;
    visibility: hidden;
  }

  .pdp_icon_badge ul li img {
    width: 25px;
    height: 25px;
    margin: 0 auto 10px;
  }

  .submit__buttons {
    gap: 20px;
    margin: 0 auto;
  }

  .pdp_pr_img_wrap {
    margin: 20px 0;
  }

  .pdp_img img {
    border-radius: 10px;
  }

  .pdp_review_wrap {
    flex-wrap: wrap;
  }

  .pdp_review_wrap .review_left,
  .pdp_review_wrap .review_right {
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
    box-shadow: none;
  }

  .add_review {
    margin: 15px 0 20px;
    width: 100%;
  }

  .add_review h5 {
    margin: 0 0 10px;
    font-size: 14px;
  }

  .pdp_review_wrap .review_left .btn_primary {
    width: 100%;
  }

  .pdp_review_wrap .review_right {
    padding: 20px 0 0;
    margin: 20px 0 0;
    border-top: 1px solid #c3c3c3;
  }

  .submit__buttons .btn_primary {
    font-size: 16px;
  }

  .payment_type input:checked + label:before {
    top: 6px;
  }

  .main__account__menu__item.active .account__menu {
    right: 50%;
    transform: translate(36%, 9px);
  }

  .account__submenu {
    padding: 10px 15px;
    gap: 10px;
    white-space: nowrap;
  }

  .account__submenu.account-info svg {
    width: 18px;
    height: 18px;
  }

  .user__name {
    font-size: 10px;
  }

  .megamenu_cat .mega-menu {
    display: block;
  }

  .pdp_other_section .pdp_other_label {
    font-size: 16px;
  }

  .product__description-item details.product__desc summary {
    font-size: 16px;
  }

  .pop_up_title h5 {
    font-size: 16px;
  }

  .page-width {
    padding: 10px 15px;
  }
  .header__wrapper {
    padding: 0px;
  }
  .pdp_coupon_wrap {
    margin: 0 -15px 0 0px;
  }

  .pdp_coupon_wrap ul li {
    width: 90%;
  }

  .coupon_content span {
    font-size: 12px;
    max-width: 150px;
  }

  .star_rating_icon h5 {
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .extra__card__item {
    display: block;
  }

  .announcement__content.desktop {
    display: none;
  }
  .announcement__content.mobile {
    display: block;
  }

  .extra__product__card-item {
    width: 100%;
    margin: 0 0 5px;
    padding: 0 10px;
  }

  .bolt_nutri_slide {
    width: 100%;
  }

  .product_atc_btn {
    gap: 10px;
  }

  .product_atc_btn img {
    width: 15px;
    height: 15px;
  }

  .card-wrapper .card-item .product__discount {
    padding: 4px 9px;
  }

  .review_pop_body h5 {
    font-size: 20px;
  }

  .brand__category__item {
    position: unset;
  }

  .pdp_icon_badge ul li p {
    font-size: 11px;
    line-height: 1.2;
  }

  .pdp_icon_badge ul li {
    min-height: 80px;
    padding: 5px 5px;
  }

  .not_available_wrap .sold_out_label h2 {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .slick-arrow.offer__prod-prev {
    display: none !important;
  }

  .back-to-top button {
    bottom: 15px;
    right: 15px;
  }

  .slick-arrow.offer__prod-next {
    display: none !important;
  }

  .hm__slider__wrapper .slick-dots li button:before {
    font-size: 6px;
  }

  button.slick-arrow.slick-prev {
    display: none !important;
  }

  button.slick-arrow.slick-next {
    display: none !important;
  }

  .brand__product-grid.grid__view.list__view {
    display: grid;
    grid-template-columns: 1fr;
  }

  .logolist__item {
    height: 80px;
    width: 80px !important;
  }

  /* .hm__icon__content h3 {
    font-size: 10px;
  } */

  .hm__icon__image img {
    width: 35px;
  }

  .hm__icon__grid-container.page-width {
    padding: 0 5px;
  }

  .logo__item img {
    max-width: 150px;
  }

  .submit__buttons .btn_primary img {
    width: 20px;
  }

  .submit__buttons .btn_primary {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .banner_sl_container {
    padding: 0 10px 15px;
  }
  .cm_banner_slider .main__image {
    margin-right: 0px;
  }
  .hm__icon__content p {
    font-size: 12px;
    max-width: 100px;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 360px) {
  .brand__img img {
    max-height: 120px;
    height: 120px;
    object-fit: cover;
  }
  .submit__buttons .btn_primary {
    font-size: 12px;
    min-width: auto;
    padding: 10px 14px;
  }
}

/* BEST SELLER CSS END */
