.orderStatusWrapper {
    padding: 30px 0;
}

.orderHistoryWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.order__items__title {
    cursor: pointer;
}

.order__items__title:hover {
    color:#E31937;
}

.historyItemContentBox {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.historyItemWrapper {
    box-shadow: 0px 0px 5px 1px #00000033;
    border-radius: 15px;
    color: #000000;
    display: flex;
    flex-direction: column;
    padding: 0 0 15px;
    background-color: #fff;   /*theme*/
}

.orderIdWrapper {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    margin: 0 0 10px;
    background-color: #fff;   /*theme*/
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.orderDetailsWrapper {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;   /*theme*/
}

.idDetails {
    display: flex;
    gap: 10px;
    align-items: center;
}

.orderIdName,
.idText {
    color: #5A5A5A;
    font-size: 16px;
}

.idDetails .idText {
    font-weight: 600;
}

.itemStatus {
    font-size: 16px;
    font-weight: 600;
}

.completed {
    color: #4FB400;

}
.discounted{

 color: #4FB400;
 font-weight: 500;
 font: var(--sup);
}

.delivery_charge{ color: black;
    font-weight: 500;
    font: var(--sup);
}

.pending {
    color:#FF3723;


}

.cancelled {
    color:#FF3723;

}

.imgShow {
    object-fit: contain;
    height: 100%;
}

.dot__line {
    border-bottom-width: 1.5px;
    border-bottom-style: dashed;
    border-bottom-color: #aaaaaa;
    margin: 5px;
}

.detailBox {
    padding: 10px 0;
    border-bottom: 1px dashed #ADADAD;
}

.detailsContent {
    display: flex;
    padding: 2px;
    gap: 5px;
    height: 100%;
    justify-content: space-between;
}

.history__item__image {
    max-height: 110px;
    width: 100%;
    max-width: 110px;
    object-fit: contain;
}

.orderDetails {
    width: 100%;


    display: flex;
    flex-direction: column;

    gap: 10px;
}

.history__item__title {
    font-size: 14px;
    max-width: 500px;
    width: 100%;
    font-weight: 400;
    color: #000000;
}

.orderedDate {
    color: #8E8E8E;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;


}

.order__status__final {
    height: 100%;

}
.flex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.order__status__final img{
    height: 100%;
    /* width: auto; */
    object-fit: contain;

}

.statusWrapper {
    height: 30%;
    padding: 10px 0px;
}

.productsDisplayContainer {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    margin-top: 20px;
}

.leftContainer {
    width: 65%;
    border: 1px solid #DEE2E7;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.rightContainer {
    width: 35%;
    border: 1px solid #DEE2E7;
    border-radius: 15px;
    padding: 15px;
}

.rightContainer_wrapper {
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0px 0px 15px 0px #0000000F;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px;
    margin: 0 0 20px;
    background-color: #fff;   /*theme*/

}

.orderStatusTrack {
    display: flex;
    flex-direction: row;
}

.linkDetails {
    display: flex;
    flex-direction: row;
    color: #000000;
    gap: 5px;
}

.actual_price {
    color: #262626;
    font-family: 'Roboto-Bold';
}

.info {
    display: flex;
    justify-content: space-between;
}

.trackingBox .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    /* color: #5A5A5A; */
}

.location__icon {
    height: 21px !important;
    width: 22px !important;
}

.addressInfo {
    display: flex;
    gap: 6px;
    font-size: 15px;
}

.order__status__final .actual_price {
    font-size: 14px;
    letter-spacing: 0;
}

.location_info {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 400;
    margin-left: 2%;
    color: #000000;
}

.discount_price {

    font-size: 16px;
    font-weight: bold;
    line-height: 18.96px;
    text-align: left;
    color: #EC4E1E;

}

.invoiceButton {
    background-color: #EC4E1E;
    border: none;
    color: white;
    height: 30px;
    width: 100px;
    border-radius: 5px;
}

.returnToOrderHistory {
    color: #000000;
    padding: 10px 0px;
}

.productWrapper .StartShopping__btn {
    padding: 5px;

}

.productWrapper {
    gap: 10px;
}

.turn180 {
    transform: rotate(180deg);
}

.reOrderWrapper {
    display: flex;
    align-items: end;
}

.reOrderWrapper .StartShopping__btn {
    display: flex;
    padding: 8px 15px;
    min-width: unset;
}

.cancel_wrapper {
    display: flex;
    justify-content: center;
    padding: 30px 50px;
}

.cancel_wrapper .StartShopping__btn {
    width: 60%;
}

.orderStatusWrapper .confirmCOD-modalContent {
   
    height: auto;

}

.reOrderWrapper .StartShopping__btn img {
    width: 18px;
}

.invoice_btn {
    display: flex;
    align-items: center;
    font: var(--sup);
    background: var(--orange);
    border: none;
    padding: 6px 15px;
    gap: 6px;
    border-radius: 6px;
    color: var(--white);
}

.info .text,
.info .idText,
.info .actual_price,
.info .discount_price {
    font: var(--sup);
}

.info .text {
    color: #8C8C8C;
}

.info .idText,
.info .actual_price,
.info .discount_price {
    font-weight: 500;
}

.product_title_order {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cancel_wrapper .btn_primary {
    max-width: 200px;
    width: 100%;
}
.red{
    color: #EC4130;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}
@media(max-width:990px) {
    .historyItemContentBox {
        width: 100%;
    }

    .leftContainer {
        width: 60%;
    }

    .rightContainer {
        width: 40%;
    }

    .productsDisplayContainer {
        gap: 15px;
    }
}


@media(max-width:767px) {

    .product_status_txt h5,
    .product_title_order h5 {
        font-size: 16px;
    }

    .trackingWrapper {
        margin: 20px 0 30px;
    }

    .orderDetailsWrapper {
        padding: 2px;
    }

    .productsDisplayContainer {
        flex-direction: column;
        gap: 0;
    }

    .leftContainer,
    .rightContainer {
        padding: 5px;
        width: 100%;
    }

    .orderHistoryWrapper .cart__container.right {
        width: 45%;
    }

    .cart__container.right {
        width: 100%;
    }

    .cart__item__title {
        max-width: 250px;
    }

    .successfull-tick {

        width: 200px;
        height: 200px;
    }

    .rightContainer {
        border: none;
        padding: 15px 0 0;
        margin: 15px 0 0;
        border-top: 1px dashed #6B6B6B;
        border-radius: 0px;
    }

    .product_title_order {
        justify-content: space-between;
    }

    .cancel_wrapper {
        padding: 10px 10px;
    }

    .account__main__heading {
        font-size: 16px;
    }

    .account__menu__item {
        font-size: 14px;
    }

    .order_header_txt h5 {
        font-size: 16px;
    }

    .orderIdWrapper {
        padding: 15px;
        background-color: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}

@media(max-width:560px) {
    .reOrderWrapper .StartShopping__btn {
        padding: 5px;
    }

    .history__item__title {
        font-size: 10px;


    }

    .orderDetails .actual_price {
        font-size: 13px !important;
    }

    .orderedDate {
        font-size: 10px;
    }

    .itemStatus {
        font-size: 15px;

    }

    .history__item__image {
        max-height: 90px;
        max-width: 90px;
    }

    .historyItemWrapper {
        border-radius: 10px;
    }

    .orderDetails {
        gap: 5px;

    }

    .idText {
        font-size: 13px;
    }

    .text {
        font-size: 13px;
    }

    .addressInfo {
        font-size: 13px;
    }

    .discount_price {
        font-size: 13px;
    }

    .productsDisplayContainer .actual_price {
        font-size: 13px;
    }

    .rightContainer_wrapper {
        padding: 15px;
        background-color: #fff;   /*theme*/
    }
}