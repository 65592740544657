

.hm__icon__grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hm__icon__image img {
  width: 60px;
  height: auto;
  margin: 0 auto;
}

.hm__icon__content p {
  font-size: 16px;
  color: #fbfbfb;
  letter-spacing: 1px;
  font-family: 'Roboto-Regular';
  font-weight: 500;
  margin-bottom: 0;
}
.why-to-choose-img{
  max-height: 190px;
  max-width: 1200px;
}

@media(max-width:767px) {
  .hm__icon__content p {
    font-size: 12px;
  }
}
